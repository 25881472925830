import { useState, useEffect, useRef } from "react"
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useGesture } from "@use-gesture/react"
import SVGLayer from '../SVGBin/SVGLayer'
import ESVGLayer from "./EstimationStation/ESVGLayer"


const PlanStage = ({
  imageSet,
  darkMode,
  pageNum,
  chosenToolOption,
  pinList,
  setPinList,
  setEditingPinIndex,
  framePos,
  setFramePos,
  currentScaleRef,
  pageConstants,
  viewBoxRef,
  removePinFunc,
  marksList,
  setMarksList,
  drawingOptions,
  addMarkFunc,
  removeMarkFunc,
  handlePinSubmit,
  addMeasureFunc,
  measureList,
  measureVis,
  eviewBoxRef,
  chosenItemsList,
  setChosenItemsList,
  productStamps,
  chosenProduct,
  addStamp,
  chosenTool,
  productVis,
  removeMeasureFunc,
  forceMeasureUpdate,
  trashStamp,
  selectedStampIndexes,
  setSelectedStampIndexes,
  setPageConstants,
  shiftToggle,
  iconRegistry,
  escButtonHit,
  freshESVG,
  freshSVG
}) => {
 
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES, REFS, AND VARIABLES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const [currentImage, setCurrentImage] = useState()

// const [initialOffset, setInitialOffset] = useState({x:-500,y:-300})
const initialOffset = useRef({x:-500,y:-300})
const stageRef = useRef()

const previousMovement = useRef([0,0])

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// REACT GESTURES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

useGesture({
  onDrag: ({ active, movement: [mx, my] }) => {
    if (active) {
      let dx = (mx - previousMovement.current[0]) / currentScaleRef.current;
      let dy = (my - previousMovement.current[1]) / currentScaleRef.current;

      // Add a damping factor based on the scale
      const damping = Math.max(currentScaleRef.current, 0.1);
      dx *= damping;
      dy *= damping;

      

      setFramePos((prev) => ({
        ...prev,
        x: prev.x + dx,
        y: prev.y + dy,
      }));
      // Store the current movement values to use as the "previous" values next time
      previousMovement.current = [mx, my];
    } else {
      initialOffset.current = { x: framePos.x, y: framePos.y };
      // Reset the previous movement values when the drag ends
      previousMovement.current = [0, 0];
    }
  },
  onPinch: ()=>{
    console.log("pinching")
  },
  onWheel: (e)=> {
    e.event.preventDefault();
    const [cursorX, cursorY] = e.xy;

    // Calculate center of the viewport
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    // Calculate how much the cursor is offset from the center of the viewport
    const offsetX = centerX - framePos.x;
    const offsetY = centerY - framePos.y;

    // Adjust for the transform origin now being top-left
    const cursorPosXInFrame = (centerX - framePos.x) / framePos.scale;
    const cursorPosYInFrame = (centerY - framePos.y) / framePos.scale;

    let newScale = framePos.scale;

    if (e.delta[1] < 0) {
        newScale += 0.05;
    } else if (e.delta[1] > 0) {
        if (framePos.scale < 0.11) { return }
        newScale -= 0.05;
    }

    // Calculate the new position of the frame so the center stays at the same place in the frame
    const newFramePosX = centerX - cursorPosXInFrame * newScale;
    const newFramePosY = centerY - cursorPosYInFrame * newScale;

    setFramePos({
        scale: newScale,
        x: newFramePosX,
        y: newFramePosY
    });

    currentScaleRef.current = newScale;
  }
},{
  target: stageRef,
  eventOptions:{passive:false},
  drag: {pointer:{buttons: [2,4]}},
})

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// USE EFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
useEffect(() => {
  initializeFramePosition();
}, []);

useEffect(()=>{
  if (imageSet.length < 1) return
  
  const newImage = (imageSet[pageNum][0])

  setCurrentImage(newImage)
  updateImageSize(newImage)
  console.log(newImage)

},[imageSet,pageNum])

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// METHODS & FUNCTIONS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const updateImageSize = (imageSrc) => {
  const img = new Image();
  img.src = imageSrc;

  img.onload = () => {
    setPageConstants(prevState => ({
      ...prevState,
      stdImgSize: { x: img.width, y: img.height },
    }));
  };
};
const initializeFramePosition = () => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // Calculate how much to translate the stage to center the SVG
  console.log(pageConstants)
  const initialX = (viewportWidth ) / 100;
  const initialY = (viewportHeight ) / 15;

  setFramePos({ x: initialX, y: initialY, scale: framePos.scale });
};

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const Blueprint = () =>{
  if (darkMode == false) {
    return(
      <div className="blueprint-frame">
        <img className="blueprintImage light" src={currentImage} ></img>
      </div>
      )
  } else if (darkMode == true) {
    return(
      <div className="blueprint-frame">
        <img className="blueprintImage dark" src={currentImage} ></img>
      </div>
      )
  } else {
    return(
      <div className="blueprint-frame">
        <img className="blueprintImage fresh" src={currentImage} ></img>
      </div>
      )
  }
}
//MAIN
//RETURN
return(
  <div ref={stageRef}
  style={{
    left: framePos.x,
    top: framePos.y,
    transform: `scale(${framePos.scale})`,
    transformOrigin: "0 0",  // Added this
    touchAction: "none",
  }} 
  className="transform-frame"
  onWheel={e=>e.stopPropagation()}
  onContextMenu={(e)=>e.preventDefault()}
  >
    <Blueprint />
    {(chosenTool == "productsTool" )&& (
    <>
      <SVGLayer
      removePinFunc={removePinFunc}
      framePos={framePos} 
      currentScaleRef={currentScaleRef} 
      chosenToolOption={chosenToolOption} 
      pageNum={pageNum} 
      pageConstants={pageConstants}
      pinList={pinList}
      setPinList={setPinList}
      setEditingPinIndex={setEditingPinIndex}
      viewBoxRef={viewBoxRef}
      marksList={marksList}
      setMarksList={setMarksList}
      drawingOptions={drawingOptions}
      addMarkFunc={addMarkFunc}
      removeMarkFunc={removeMarkFunc}
      handlePinSubmit={handlePinSubmit}
      addMeasureFunc={addMeasureFunc}
      measureList={measureList}
      measureVis={measureVis}
      removeMeasureFunc={removeMeasureFunc}
      forceMeasureUpdate={forceMeasureUpdate}
      shiftToggle={shiftToggle}
      escButtonHit={escButtonHit}
      key={freshSVG}
    />
    {productVis == true && (
      <ESVGLayer
        framePos={framePos} 
        currentScaleRef={currentScaleRef} 
        chosenToolOption={chosenToolOption} 
        pageNum={pageNum} 
        pageConstants={pageConstants}
        eviewBoxRef={eviewBoxRef}
        chosenItemsList={chosenItemsList}
        setChosenItemsList={setChosenItemsList}
        productStamps={productStamps}
        chosenProduct={chosenProduct}
        addStamp={addStamp}
        trashStamp={trashStamp}
        setSelectedStampIndexes={setSelectedStampIndexes}
        selectedStampIndexes={selectedStampIndexes}
        darkMode={darkMode}
        shiftToggle={shiftToggle}
        iconRegistry={iconRegistry}
        escButtonHit={escButtonHit}
        key={freshESVG}
      />
    )}
    </>
    )}
    {(chosenTool != "productsTool" )&& (
    <>
    {productVis == true && (
      <ESVGLayer
        framePos={framePos} 
        currentScaleRef={currentScaleRef} 
        chosenToolOption={chosenToolOption} 
        pageNum={pageNum} 
        pageConstants={pageConstants}
        eviewBoxRef={eviewBoxRef}
        chosenItemsList={chosenItemsList}
        setChosenItemsList={setChosenItemsList}
        productStamps={productStamps}
        chosenProduct={chosenProduct}
        addStamp={addStamp}
        trashStamp={trashStamp}
        selectedStampIndexes={selectedStampIndexes}
        setSelectedStampIndexes={setSelectedStampIndexes}
        darkMode={darkMode}
        shiftToggle={shiftToggle}
        iconRegistry={iconRegistry}
        escButtonHit={escButtonHit}
        key={freshESVG}
      />
    )}
      <SVGLayer
      removePinFunc={removePinFunc}
      framePos={framePos} 
      currentScaleRef={currentScaleRef} 
      chosenToolOption={chosenToolOption} 
      pageNum={pageNum} 
      pageConstants={pageConstants}
      pinList={pinList}
      setPinList={setPinList}
      setEditingPinIndex={setEditingPinIndex}
      viewBoxRef={viewBoxRef}
      marksList={marksList}
      setMarksList={setMarksList}
      drawingOptions={drawingOptions}
      addMarkFunc={addMarkFunc}
      removeMarkFunc={removeMarkFunc}
      handlePinSubmit={handlePinSubmit}
      addMeasureFunc={addMeasureFunc}
      measureList={measureList}
      measureVis={measureVis}
      removeMeasureFunc={removeMeasureFunc}
      shiftToggle={shiftToggle}
      escButtonHit={escButtonHit}
      key={freshSVG}
      
    />
    
    </>
    )}
    
  </div>
)
}

export default PlanStage