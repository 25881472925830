import { useEffect,useState } from "react"
import Stamps from "./Stamps"
import StampTrashMessage from './StampTrashMessage'
import MouseMessageBoard from "./MouseMessageBoard"
import LassoMessage from "./LassoMessage"
const ESVGLayer = ({
      framePos,
      currentScaleRef,
      chosenToolOption,
      pageNum,
      pageConstants,
      eviewBoxRef,
      chosenItemsList,
      setChosenItemsList,
      productStamps,
      addStamp,
      chosenProduct,
      trashStamp,
      selectedStampIndexes,
      setSelectedStampIndexes,
      darkMode,
      shiftToggle,
      iconRegistry,
      escButtonHit

}) => {
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES & REFS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const [toolCursor,setToolCursor] = useState("deafult")
const [hoverStampData,setHoverStampData] = useState()

////// LASSO //////
const [isDragging, setIsDragging] = useState(false);
const [lassoSettings,setLassoSettings]=useState("black")
const [selectionRect, setSelectionRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
/////// STAMPS & PRODUCTS //////
const [activeStampType,setActiveStampType] = useState("")
//LINE TOOL
const [currentLinePath,setCurrentLinePath] = useState([])
const [currentLineDistance,setCurrentLineDistance] = useState(0)
const [currentLinePoints,setCurrentLinePoints] = useState(null)
const [isDrawingLinePath,setIsDrawingLinePath] = useState(false)
//BOX AREA
const [currentAreaDimension, setCurrentAreaDimension] = useState(null);
const [isDrawingAreaDimension, setIsDrawingAreaDimension] = useState(false);
//MEASURE AREA
const [currentPolygonPath, setCurrentPolygonPath] = useState([]);
const [currentPolygonPoints, setCurrentPolygonPoints] = useState(null);
const [isDrawingPolygonPath, setIsDrawingPolygonPath] = useState(false);
const [currentTemporaryPolygonPath,setCurrentTemporaryPolygonPath] = useState([])


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// USEEFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
useEffect(()=>{
  setLassoSettings(darkMode==true?"white":"black")
},[darkMode])
useEffect(()=>{
console.log('hi')
console.log(shiftToggle)
},[shiftToggle])
useEffect(()=>{
  if (chosenItemsList[chosenProduct]) {
    console.log(chosenItemsList[chosenProduct].type)
    setActiveStampType(chosenItemsList[chosenProduct].type)
    setCurrentLinePath([])
    setCurrentLineDistance(0)
    setCurrentLinePoints(null);
    setIsDrawingLinePath(false)
    setCurrentAreaDimension(null)
    setIsDrawingAreaDimension(false)
    setCurrentPolygonPath([])
    setCurrentPolygonPoints(null)
    setIsDrawingPolygonPath(false)
    setCurrentTemporaryPolygonPath([])
  }
},[chosenProduct])
// useEffect(()=>{
//   setIsDragging(false)
//   setSelectionRect({ x: 0, y: 0, width: 0, height: 0 })
//   setActiveStampType("")
//   setCurrentLinePath([])
//   setCurrentLineDistance(0)
//   setCurrentLinePoints(null);
//   setIsDrawingLinePath(false)
//   setCurrentAreaDimension(null)
//   setIsDrawingAreaDimension(false)
//   setCurrentPolygonPath([])
//   setCurrentPolygonPoints(null)
//   setIsDrawingPolygonPath(false)
//   setCurrentTemporaryPolygonPath([])
//   console.log("ESVG CLEARED")
// },[escButtonHit])
//STUPID OLD CODE, WORKS IF NOTHING ELSE
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// METHODS & FUNCTIONS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
// INCHAGE //
function convertToImperial(d) {
  // get the whole feet
  let feet = Math.floor(d);
  
  // get the remaining part in feet and convert to inches
  let decimalFeet = d - feet;
  let totalInches = decimalFeet * 12;
  
  // get the whole inches
  let inches = Math.floor(totalInches);
  
  // get the remaining part in inches and convert to sixteenths
  let decimalInches = totalInches - inches;
  let sixteenths = Math.round(decimalInches * 16);
  
  // if sixteenths is 16, it means we have a whole inch
  if (sixteenths === 16) {
    inches++;
    sixteenths = 0;
  }
  
  // if inches is 12, it means we have a whole foot
  if (inches === 12) {
    feet++;
    inches = 0;
  }

  // Simplify sixteenths fraction and convert to string
  let fracString = "";
  if (sixteenths % 2 === 0) {
    sixteenths /= 2;
    if (sixteenths % 2 === 0) {
      sixteenths /= 2;
    }
  }
  let fracArray = []
  if (sixteenths !== 0) {
    fracString = (sixteenths === 1) ? "1/16\"" : (sixteenths === 2) ? "1/8\"" : (sixteenths === 3) ? "3/16\"" : (sixteenths === 4) ? "1/4\"" : (sixteenths === 5) ? "5/16\"" : (sixteenths === 6) ? "3/8\"" : (sixteenths === 7) ? "7/16\"" : (sixteenths === 8) ? "1/2\"" : (sixteenths === 9) ? "9/16\"" : (sixteenths === 10) ? "5/8\"" : (sixteenths === 11) ? "11/16\"" : (sixteenths === 12) ? "3/4\"" : (sixteenths === 13) ? "13/16\"" : (sixteenths === 14) ? "7/8\"" : "15/16\"";
    fracArray = (sixteenths === 1) ? [1,16] : (sixteenths === 2) ? [1,8] : (sixteenths === 3) ? [3,16] : (sixteenths === 4) ? [1,4] : (sixteenths === 5) ? [5,16] : (sixteenths === 6) ? [3,8] : (sixteenths === 7) ? [7,16] : (sixteenths === 8) ? [1,2] : (sixteenths === 9) ? [9,16] : (sixteenths === 10) ? [5,8] : (sixteenths === 11) ? [11,16] : (sixteenths === 12) ? [3,4] : (sixteenths === 13) ? [13,16] : (sixteenths === 14) ? [7,8] : [15,16];
  }

  return {
    ft: feet,
    inch: inches,
    frac: fracArray,
  };
}


/////// LASSO ///////
const isInsideRectangle = (stamp, rect) => {
  if (stamp.type === "line") {
    return isInsideRect(stamp.startingPoint, rect) && isInsideRect(stamp.endingPoint, rect);
  }

  if (stamp.type === "areaBox") {
    return isInsideRect({ x: stamp.startX, y: stamp.startY }, rect) &&
           isInsideRect({ x: stamp.endX, y: stamp.startY }, rect) &&
           isInsideRect({ x: stamp.startX, y: stamp.endY }, rect) &&
           isInsideRect({ x: stamp.endX, y: stamp.endY }, rect);
  }

  if (stamp.type === "polygon" ) {
    return stamp.path.every(point => isInsideRect(point, rect));
  }
  if (stamp.type === "stamp") {
    return isInsideRect({x:stamp.x,y:stamp.y}, rect)
  }

  return false; // Default for unsupported shape types
};

const isInsideRect = (point, rect) => {
  return point.x >= rect.x &&
         point.x <= rect.x + rect.width &&
         point.y >= rect.y &&
         point.y <= rect.y + rect.height;
};

const selectItemsInRectangle = () => {
  const selectedIds = productStamps.map((d) => {
    if (isInsideRectangle(d, selectionRect) && d.pageNum === pageNum) {
      return d.id; // Return the Firebase document ID
    }
    return null;
  }).filter(id => id !== null);

  // Do something with the selected IDs (e.g., save them to state)
  console.log(selectedIds);
  setSelectedStampIndexes(selectedIds);
};

/////// STAMPS ///////
/// ALL ///
const getDistance = (point1, point2) => {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.sqrt(dx * dx + dy * dy);
}
/// LINE TOOL ///
const segmentLengths = () => {
  if (currentLinePath.length === 0) return [];

  return currentLinePath.reduce((acc, segment, index) => {
    if (index === 0) return acc;
    const dx = segment.x - currentLinePath[index - 1].x;
    const dy = segment.y - currentLinePath[index - 1].y;
    return acc.concat(Math.sqrt(dx * dx + dy * dy));
  }, []);
};
const getPathData = (coordinates) => {
  let pathData = `M ${coordinates[0].x} ${coordinates[0].y}`;
  for(let i=1; i<coordinates.length; i++){
      pathData += ` L ${coordinates[i].x} ${coordinates[i].y}`;
  }
  return pathData;
}
const snapToAngle = (startX, startY, x, y) => {
  let endX = x;
  let endY = y;
  const dx = endX - startX;
  const dy = endY - startY;
  const angle = Math.atan2(dy, dx);
  const snappedAngle = Math.round(angle / (Math.PI / 4)) * (Math.PI / 4);
  const distance = Math.sqrt(dx * dx + dy * dy);
  endX = startX + Math.cos(snappedAngle) * distance;
  endY = startY + Math.sin(snappedAngle) * distance;
  return { x: endX, y: endY };
};


const calculatePolygonArea = (points) => {
  let n = points.length;
  let area = 0;

  for (let i = 0; i < n; i++) {
    let nextIndex = (i + 1) % n;
    let prevIndex = (i - 1 + n) % n; // Adding n to handle negative values

    area += points[i].x * (points[nextIndex].y - points[prevIndex].y);
  }

  return Math.abs(area) / 2;
};
//MAYBE another day old friend..
// const getPolygonPathData = (points) => {
//   return points.map((point, index) => {
//     return `${index === 0 ? "M" : "L"} ${point.x} ${point.y}`;
//   }).join(' ') + " Z"; // "Z" to close the path
// };

// ////// POLY INTERSECTION CALCULATIONS //////
// const onSegment = (p, q, r) => {
//   return (
//     q.x <= Math.max(p.x, r.x) &&
//     q.x >= Math.min(p.x, r.x) &&
//     q.y <= Math.max(p.y, r.y) &&
//     q.y >= Math.min(p.y, r.y)
//   );
// };

// const orientation = (p, q, r) => {
//   const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
//   if (val === 0) return 0;
//   return val > 0 ? 1 : 2;
// };

// const doIntersect = (p1, q1, p2, q2) => {
//   const o1 = orientation(p1, q1, p2);
//   const o2 = orientation(p1, q1, q2);
//   const o3 = orientation(p2, q2, p1);
//   const o4 = orientation(p2, q2, q1);

//   if (o1 !== o2 && o3 !== o4) return true;

//   if (o1 === 0 && onSegment(p1, p2, q1)) return true;
//   if (o2 === 0 && onSegment(p1, q2, q1)) return true;
//   if (o3 === 0 && onSegment(p2, p1, q2)) return true;
//   if (o4 === 0 && onSegment(p2, q1, q2)) return true;

//   return false;
// };

// const isSelfIntersecting = (path) => {
//   const n = path.length;
//   for (let i = 0; i < n; i++) {
//     for (let j = i + 1; j < n; j++) {
//       if (j !== i + 1 && j !== i && i !== 0) {
//         if (doIntersect(path[i], path[(i + 1) % n], path[j], path[(j + 1) % n])) {
//           return true;
//         }
//       }
//     }
//   }
//   return false;
// };

// const findIntersectionPoints = (polygon) => {
//   const intersections = [];
//   const n = polygon.length;
//   for (let i = 0; i < n; i++) {
//     for (let j = i + 1; j < n; j++) {
//       if (j !== i + 1 && j !== i && i !== 0) {
//         if (doIntersect(polygon[i], polygon[(i + 1) % n], polygon[j], polygon[(j + 1) % n])) {
//           intersections.push({ i: i, j: j });
//         }
//       }
//     }
//   }
//   return intersections;
// };










const getSvgP = (e) => {
  const svg = eviewBoxRef.current;
  const pt = svg.createSVGPoint();
  pt.x = e.clientX;
  pt.y = e.clientY;
  return (pt.matrixTransform(svg.getScreenCTM().inverse()));
}
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// MOUSE INTERACTION HANDLERS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

const handleMouseDown = (e) => {
  if (e.button === 0) {  // Check for left click
    let svgP = getSvgP(e)
     if (chosenToolOption === "lassoTool") {
      console.log("begin drag sequence here");
      setIsDragging(true);
      setSelectionRect({ x: svgP.x, y: svgP.y, startX: svgP.x, startY: svgP.y, width: 0, height: 0 });
    }
    else if (chosenProduct != null && chosenProduct != -1) {
      if (activeStampType=="stamp") {
        let assembler = {
          pageNum:pageNum,
          type:"stamp",
          x:svgP.x,
          y:svgP.y,
          color:chosenItemsList[chosenProduct].color,
          shape:chosenItemsList[chosenProduct].shape,
          matchingId:chosenItemsList[chosenProduct].id,
          icon:chosenItemsList[chosenProduct].icon,
          multiplier: 1,
        }
        addStamp(assembler)
      } else if (activeStampType == "line") {
        if (isDrawingLinePath) {
          let point = { x: svgP.x, y: svgP.y };
          if (shiftToggle) {
            const lastPointPullout = currentLinePath[currentLinePath.length - 1];
            point = snapToAngle(lastPointPullout.x, lastPointPullout.y, svgP.x, svgP.y);
          }
          let distanceSegment = getDistance(currentLinePath[currentLinePath.length - 1], point);
          setCurrentLinePath(prev => ([...prev, point]));
          setCurrentLineDistance(currentLineDistance + distanceSegment);
          setCurrentLinePoints({ startX: point.x, startY: point.y, endX: point.x, endY: point.y });
        } else {
          setCurrentLinePath([{ x: svgP.x, y: svgP.y }]);
          setCurrentLinePoints({ startX: svgP.x, startY: svgP.y, endX: svgP.x, endY: svgP.y });
          setIsDrawingLinePath(true);
        }
      } else if (chosenItemsList[chosenProduct] && activeStampType=="boxArea"){
          if (!isDrawingAreaDimension) {
            setCurrentAreaDimension({
              startX: svgP.x,
              startY: svgP.y,
              endX: svgP.x,
              endY: svgP.y,
            });
            setIsDrawingAreaDimension(true);
          }
      } else if (activeStampType=="drawArea"){
        if (isDrawingPolygonPath) {
          let point = { x: svgP.x, y: svgP.y };
          if (shiftToggle) {
            const lastPointPullout = currentPolygonPath[currentPolygonPath.length - 1];
            point = snapToAngle(lastPointPullout.x, lastPointPullout.y, svgP.x, svgP.y);
          }
          const tempPath = [...currentPolygonPath, point];
            setCurrentPolygonPath(prev => ([...prev, point]));
            setCurrentPolygonPoints({ startX: point.x, startY: point.y, endX: point.x, endY: point.y });
        } else {
          setCurrentPolygonPath([{ x: svgP.x, y: svgP.y }]);
          setCurrentPolygonPoints({ startX: svgP.x, startY: svgP.y, endX: svgP.x, endY: svgP.y });
          setIsDrawingPolygonPath(true);
        }
      }
    }
    
  }
}
const handleMouseMove = (e) => {
  let svgP = getSvgP(e);
  if (chosenToolOption === "lassoTool" && isDragging) {
    console.log("handle drag sequence here");
    setSelectionRect(rect => {
      const width = svgP.x - rect.startX;
      const height = svgP.y - rect.startY;
      const x = width < 0 ? rect.startX + width : rect.startX;
      const y = height < 0 ? rect.startY + height : rect.startY;
      return {
        x,
        y,
        startX: rect.startX,
        startY: rect.startY,
        width: Math.abs(width),
        height: Math.abs(height)
      };
    });
  } else if (currentLinePoints && isDrawingLinePath) {
    const snappedEnd = shiftToggle ? snapToAngle(currentLinePoints.startX, currentLinePoints.startY, svgP.x, svgP.y) : svgP;
    setCurrentLinePoints(prev => ({ ...prev, endX: snappedEnd.x, endY: snappedEnd.y }));
  }else if (chosenItemsList[chosenProduct] && currentAreaDimension && isDrawingAreaDimension) {
    let endX = svgP.x;
    let endY = svgP.y;
    
    if (shiftToggle) { // Check if the Shift key is down
      const width = Math.abs(currentAreaDimension.startX - svgP.x);
      const height = Math.abs(currentAreaDimension.startY - svgP.y);
      const size = Math.min(width, height); // Take the minimum size
      endX = currentAreaDimension.startX < svgP.x ? currentAreaDimension.startX + size : currentAreaDimension.startX - size;
      endY = currentAreaDimension.startY < svgP.y ? currentAreaDimension.startY + size : currentAreaDimension.startY - size;
    }
  
    setCurrentAreaDimension(prev => ({
      ...prev,
      endX: endX,
      endY: endY,
    }));
  }else if (currentPolygonPoints && isDrawingPolygonPath) {
    const svgP = getSvgP(e); // Assuming you have a function to translate to SVG coordinates
    const snappedEnd = shiftToggle ? snapToAngle(currentPolygonPoints.startX, currentPolygonPoints.startY, svgP.x, svgP.y) : svgP;
    setCurrentPolygonPoints(prev => ({ ...prev, endX: snappedEnd.x, endY: snappedEnd.y }));
    const temporaryPolygonPath = [...currentPolygonPath, { x: snappedEnd.x, y: snappedEnd.y }];
    setCurrentTemporaryPolygonPath(temporaryPolygonPath);
  }
}
const handleMouseUp = (e) => {
  let svgP = getSvgP(e)
  if (chosenToolOption == "lassoTool" && isDragging) {
    console.log("end drag sequence here")
    setIsDragging(false);
    console.log(selectionRect)
    // You can do the filtering here or in a separate function
    // using the selectionRect to determine which items are inside
    selectItemsInRectangle();
  }
  // console.log("mouse up")
}

const handleMouseLeave = () => {
  let newMeasure = {}
  if (chosenToolOption == "lassoTool" && isDragging) {
    setIsDragging(false);
  } else if (chosenItemsList[chosenProduct] && currentAreaDimension && isDrawingAreaDimension) {
    // const area = Math.abs(currentAreaDimension.endX - currentAreaDimension.startX) * Math.abs(currentAreaDimension.endY - currentAreaDimension.startY);
    // const newBoxStamp = {
    //   ...currentAreaDimension,
    //   type: "areaDimension",
    //   area: area.toFixed(5),
    //   pageNum: pageNum,
    //   color: chosenItemsList[chosenProduct].color,
    //   size: 10,
    // };
    // addStamp(newBoxStamp);
    // setCurrentAreaDimension(null);
    // setIsDrawingAreaDimension(false);
    //FIX THIS LATER
  }
  
}

const handleDoubleClick = (e) => {
  let newMeasure = {}
  let svgP = getSvgP(e)
  // console.log("dub click")
  if (activeStampType == "line" && isDrawingLinePath) {
    const newPath = currentLinePath.slice(0, -1); // remove the last point

    let distance = currentLineDistance
    const lastSegmentDistance = getDistance(currentLinePath[currentLinePath.length - 2], currentLinePath[currentLinePath.length - 1]);
    const newDistance = currentLineDistance - lastSegmentDistance;
    let ft = (newDistance / 200 * pageConstants.pageScale)
    let segLegs = segmentLengths()
    const firstPoint = currentLinePath[0]
    const lastPoint = newPath[newPath.length  -1]
    console.log(currentLinePath)
    console.log(newPath)
    let newLineMeasure = {
      path: newPath,
      pageNum:pageNum,
      startingPoint: firstPoint, 
      endingPoint: lastPoint,
      type: "line",
      shape:"line",
      size: 10,
      multiplier:1,
      color: chosenItemsList[chosenProduct].color,
      matchingId:chosenItemsList[chosenProduct].id,
      distance: newDistance,
      distanceFT:convertToImperial(ft),
      // distanceMM:, ONE DAY LADS
      length: segLegs.reduce((a, b) => a + b, 0),
    }
    console.log(newLineMeasure)
  if (Object.keys(newLineMeasure).length > 0) {
    addStamp(newLineMeasure)
  }
  setIsDrawingLinePath(false)
  setCurrentLinePath([])
  setCurrentLinePoints({})
  setCurrentLineDistance(0)
  } else if (chosenItemsList[chosenProduct] && currentAreaDimension && isDrawingAreaDimension) {
    let width = Math.abs(currentAreaDimension.endX - currentAreaDimension.startX) / 200 * pageConstants.pageScale; // convert from px to ft
    let height = Math.abs(currentAreaDimension.endY - currentAreaDimension.startY) / 200 * pageConstants.pageScale; // convert from px to ft
    let sqft = width * height; // area in sq ft
    const area = Math.abs(currentAreaDimension.endX - currentAreaDimension.startX) * Math.abs(currentAreaDimension.endY - currentAreaDimension.startY);
    const newBoxStamp = {
      ...currentAreaDimension,
      type: "areaBox",
      shape:"areaBox",
      distance: currentAreaDimension,
      areaFT:convertToImperial(sqft),
      area: area.toFixed(5),
      pageNum: pageNum,
      color: chosenItemsList[chosenProduct].color,
      size: 10,
      multiplier:1,
      matchingId:chosenItemsList[chosenProduct].id
    };
    addStamp(newBoxStamp);
    setCurrentAreaDimension(null);
    setIsDrawingAreaDimension(false);
  }else if (activeStampType == "drawArea" && isDrawingPolygonPath) {
    // Calculating the area of the completed polygon
    const newPath = currentPolygonPath.slice(0, -1); // remove the last point
    const polygonArea = calculatePolygonArea(currentPolygonPath);
    const polygonAreaFT = polygonArea / (200 * 200) * pageConstants.pageScale * pageConstants.pageScale;

    const newPolygonStamp = {
      path: newPath,
      type: "polygon",
      shape: "polygon",
      areaFT: convertToImperial(polygonAreaFT), // Assuming a conversion function exists
      color: chosenItemsList[chosenProduct].color,
      size: 10,
      multiplier:1,
      pageNum:pageNum,
      matchingId:chosenItemsList[chosenProduct].id
      // Add any additional properties as required
    };
    
    addStamp(newPolygonStamp);
    setCurrentPolygonPath([]);
    setIsDrawingPolygonPath(false);
    setCurrentTemporaryPolygonPath([])
  }
}
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
  return(
<div className={`pin-layer ${chosenToolOption}`} style={{
  gridRowStart: 1,
  gridColumnStart: 1,
  zIndex: 10,
  cursor: `${toolCursor}`
}}>
<svg xmlns="http://www.w3.org/2000/svg" 
viewBox={`0 0 ${pageConstants.stdImgSize.x} ${pageConstants.stdImgSize.y}`}
onMouseDown={handleMouseDown}
onMouseMove={handleMouseMove}
onMouseUp={handleMouseUp}
onMouseLeave={handleMouseLeave}
onDoubleClick={handleDoubleClick}
ref={eviewBoxRef}
>
  {productStamps.map((d,i)=>{
    return(
    <Stamps
      setHoverStampData={setHoverStampData}
      hoverStampData={hoverStampData}
      selectedStampIndexes={selectedStampIndexes}
      setSelectedStampIndexes={setSelectedStampIndexes}
      trashStamp={trashStamp}
      chosenToolOption={chosenToolOption} 
      pageNum={pageNum}
      pageConstants={pageConstants}
      chosenItemsList={chosenItemsList} 
      chosenProduct={chosenProduct} 
      d={d}i={i}key={i}
      
    />)
  })}
    {chosenToolOption === "lassoTool" && isDragging &&
      <rect x={selectionRect.x} y={selectionRect.y} 
            width={selectionRect.width} height={selectionRect.height}
            stroke={lassoSettings} fill="transparent" strokeWidth={20}
            strokeDasharray="20, 20" // Sets up a dashed line with 5px dashes and 5px gaps
            style={{ animation: 'dash 1s linear infinite' }} />
    }
    {(currentAreaDimension && chosenItemsList[chosenProduct]) && (
        <rect 
          x={Math.min(currentAreaDimension.startX, currentAreaDimension.endX)}
          y={Math.min(currentAreaDimension.startY, currentAreaDimension.endY)}
          width={Math.abs(currentAreaDimension.startX - currentAreaDimension.endX)}
          height={Math.abs(currentAreaDimension.startY - currentAreaDimension.endY)}
          fill={chosenItemsList[chosenProduct].color}
          fillOpacity={0.3}
        />
      )}
    {(currentLinePath.length > 0 && chosenItemsList[chosenProduct]) && (
      <path 
          d={getPathData(currentLinePath)}
          stroke={chosenItemsList[chosenProduct].color}
          fill="none"
          strokeWidth={10} //NEED TO IMPLEMENT SIZING!!
      />
    )}
    {(currentLinePoints&& chosenItemsList[chosenProduct]) && (
      <line
      x1={currentLinePoints.startX}
      y1={currentLinePoints.startY}
      x2={currentLinePoints.endX}
      y2={currentLinePoints.endY}
      stroke={chosenItemsList[chosenProduct].color}
      strokeWidth={10}
      />
    )}
     {(currentTemporaryPolygonPath.length > 1 && chosenItemsList[chosenProduct]) && (
      <polygon
        points={currentTemporaryPolygonPath.map(p => `${p.x},${p.y}`).join(' ')}
        fill={chosenItemsList[chosenProduct].color}
        fillOpacity={0.3}
        stroke={chosenItemsList[chosenProduct].color}
        strokeWidth={10}
      />
    )}
    {/* Rendering the live preview line segment */}
    {(currentPolygonPoints && chosenItemsList[chosenProduct]) && (
      <line
        x1={currentPolygonPoints.startX}
        y1={currentPolygonPoints.startY}
        x2={currentPolygonPoints.endX}
        y2={currentPolygonPoints.endY}
        stroke={chosenItemsList[chosenProduct].color}
        strokeWidth={10}
      />
    )}
  {(chosenToolOption === "lassoTool") && <LassoMessage framePos={framePos} eviewBoxRef={eviewBoxRef} />}
  {(chosenToolOption === "trashProductStamp") && <StampTrashMessage framePos={framePos} eviewBoxRef={eviewBoxRef} />}
  {(chosenToolOption==="" && chosenProduct===null) && <MouseMessageBoard iconRegistry={iconRegistry} chosenItemsList={chosenItemsList} d={hoverStampData} framePos={framePos} eviewBoxRef={eviewBoxRef} />}
</svg>
</div>
    
  )
}

export default ESVGLayer