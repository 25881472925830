
import { useState,useEffect,useRef,useLayoutEffect } from "react"

const Pin = ({
  data,
  index,
  pageNum,
  chosenToolOption,
  setEditingPinIndex,
  removePinFunc,
  setDraggingPinIndex,
  viewBoxRef,
  handlePinSubmit,
}) => {

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES & REFS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const [x,setX]=useState(data.x)
const [y,setY]=useState(data.y)
const [infoToggle,setInfoToggle]=useState(false)
const [hoverToggle, setHoverToggle]=useState(false)
const [pinTitle, setPinTitle]=useState(data.pinTitle)
const [pinType,setPinType]=useState(false)
const [pinNotes, setPinNotes]=useState(data.notes)
const [pinColor, setPinColor]=useState(data.color)
const [boxHeight, setBoxHeight] = useState(0);  // NEW: state for the box height
const [hoverHeight,setHoverHeight] = useState(0)
const diagBoxRef = useRef(null);  // NEW: ref for the div
const hoverRef = useRef(null)

const [dragging, setDragging] = useState(false);
const [relativePos, setRelativePos] = useState({ x: 0, y: 0 });

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// USEEFFECTS AND USELAYOUTEFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
useLayoutEffect(() => {
  if (infoToggle && diagBoxRef.current) {
    setBoxHeight(diagBoxRef.current.offsetHeight);  // NEW: read the height of the div
  }
  if (infoToggle && hoverRef.current) {
    setHoverHeight(hoverRef.current.offsetHeight)
  }
}, [infoToggle, pinNotes]);
useEffect(()=>{
  setPinTitle(data.pinTitle)
  setPinNotes(data.notes)
  setPinColor(data.color)
  setX(data.x)
  setY(data.y)
  setInfoToggle(false)
  setHoverToggle(false)
  
},[data])
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// METHODS AND FUNCTIONS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
 
const handleMouseDown = (e) => {
  if (e.button !== 0 || chosenToolOption != "movePin") return; // Only left button
  const svg = viewBoxRef.current;
  const pt = svg.createSVGPoint();
  pt.x = e.clientX;
  pt.y = e.clientY;
  const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
  setRelativePos({
    x: svgP.x - data.x,
    y: svgP.y - data.y,
  });
  setDragging(true);
};

const handleMouseMove = (e) => {
  if (!dragging) return;
  const svg = viewBoxRef.current;
  const pt = svg.createSVGPoint();
  pt.x = e.clientX;
  pt.y = e.clientY;
  const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
  setX(svgP.x - relativePos.x);
  setY(svgP.y - relativePos.y);
};

const handleMouseUp = () => {
  if (!dragging) return;
  setDragging(false);
  let pinDataProxy = {
    ...data,
    x: x,
    y: y,
  }
  handlePinSubmit(pinDataProxy)
};

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////


const MessageBoard = () => {
  if (infoToggle == true) return (
    <foreignObject x={x - 550} y={y - hoverHeight - 30} width="70em" height="140em">
      <div ref={diagBoxRef} style={{
        backgroundColor: "rgba(255,255,255,1)",
        border: `0.8em solid ${data.color}`,
        borderRadius: "10px",
        padding: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
        flexDirection: "column-reverse",
        width: "100%",  // NEW: make sure the div fills the entire width of the foreignObject
        boxSizing: "border-box",  // NEW: include padding and border in the element's total width and height
      }}>
        <div style={{display:"flex",flexDirection:"column"}}>
          {pinNotes.map((note)=>{return(<div key={Math.random()} style={{fontSize: "3.5em"}}>-{note.timeStamp}--{note.note}</div>)})}
        </div>
        <div style={{fontSize:"5em",fontWeight:"bold"}}>{pinTitle}</div>
      </div>
    </foreignObject>
    )}
const HoverBoard = () => {
  if (hoverToggle == true) return (
    <foreignObject x={x - 550} y={y - hoverHeight - 30} width="70em" height="20em">
      <div
        ref={hoverRef}
        style={{
          backgroundColor: "rgba(255,255,255,1)",
          border: `0.8em solid ${data.color}`,
          borderRadius: "10px",
          padding: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div style={{fontSize:"5em",fontWeight:"bold"}}>{pinTitle}</div>
      </div>
    </foreignObject>
  );
}

if (data.pageNum == pageNum) {
return(
  <g className={`pinGroup ${chosenToolOption}`} 
  onMouseDown={handleMouseDown}
  onMouseMove={handleMouseMove}
  onMouseUp={handleMouseUp}
  onClick={() => {
    if (chosenToolOption != "trashMarkup") {
      if (chosenToolOption == "editPin") {
        console.log('EDIT')
        setEditingPinIndex(data.id)
      }else if(chosenToolOption == "trashPin") {
        console.log('DELETE')
        removePinFunc(data,index)
      } else {
        setInfoToggle(prevState => !prevState)
      }
    }

  } }
      onMouseEnter={() => { if(chosenToolOption != "trashMarkup") setHoverToggle(true)} }
      onMouseLeave={() => {setHoverToggle(false)} }
    >
      {hoverToggle && !infoToggle && <HoverBoard />}
      {<MessageBoard key={index} />}
      <g className={`pinShape ${infoToggle ? 'active' : ''}`}>
        <line x1={x+3} y1={y-120} x2={x} y2={y} stroke="black" strokeWidth="12"/>
        <line x1={x-3} y1={y-120} x2={x} y2={y} stroke={`url(#grad${index})`} strokeWidth="12"/>
        <circle cx={x} cy={y-170} r="77" fill={`url(#grad${index})`}/>
        <circle cx={x} cy={0} r="30" fill="rgba(0,0,0,0.1)" />
    </g>
    
  </g>
)
}
}

export default Pin