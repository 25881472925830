import {useState,useEffect} from 'react'
import tinycolor from 'tinycolor2'
import StampMessage from './StampMessage'


const Stamps = ({setHoverStampData,pageConstants,selectedStampIndexes,setSelectedStampIndexes,trashStamp,pageNum,countState,chosenProduct,chosenItemsList,chosenToolOption,d,i}) => {
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
  const [hoverToggle,setHoverToggle] = useState(false)
  const [stampClass,setStampClass] = useState(`stamps ${hoverToggle}`)
  const [messageBoardToggle,setMessageBoardToggle] = useState(false)
  const [stampTitle,setStampTitle] = useState("")

  const [boxScale, setBoxScale] = useState()
  const [fontScale, setFontScale] = useState()
  const [imperialMeasure,setImperialMeasure] = useState({frac:[1,1]})


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// USE EFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
useEffect(()=>{
  setBoxScale(d.size/1.25)
  setFontScale(d.size/2)
},[])
useEffect(()=>{
  if (selectedStampIndexes) {
  chosenItemsList.map((dat,i)=>{
    if (dat.id == d.matchingId) {
      setStampTitle(dat.title)
    }
  })
  const isSelected = selectedStampIndexes.includes(d.id);
  const stampClass = `stamps ${hoverToggle} ${isSelected ? 'selected' : ''}`;
  setStampClass(stampClass)
  }
},[selectedStampIndexes, hoverToggle])
useEffect(()=>{
  if (d.type == "line") {
  let distance = d.distance
  let ft = (distance / 200 * pageConstants.pageScale)
  setImperialMeasure(convertToImperial(ft))
  } else if (d.type === "boxArea") {
    // Calculate the area
    let width = Math.abs(d.endX - d.startX) / 200 * pageConstants.pageScale; // convert from px to ft
    let height = Math.abs(d.endY - d.startY) / 200 * pageConstants.pageScale; // convert from px to ft
    let sqft = width * height; // area in sq ft
  
    // Convert to imperial
    setImperialMeasure(convertToImperial(sqft));
  }
},[pageConstants])

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// METHODS & FUNCS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

  function convertToImperial(d) {
    // get the whole feet
    let feet = Math.floor(d);
    
    // get the remaining part in feet and convert to inches
    let decimalFeet = d - feet;
    let totalInches = decimalFeet * 12;
    
    // get the whole inches
    let inches = Math.floor(totalInches);
    
    // get the remaining part in inches and convert to sixteenths
    let decimalInches = totalInches - inches;
    let sixteenths = Math.round(decimalInches * 16);
    
    // if sixteenths is 16, it means we have a whole inch
    if (sixteenths === 16) {
      inches++;
      sixteenths = 0;
    }
    
    // if inches is 12, it means we have a whole foot
    if (inches === 12) {
      feet++;
      inches = 0;
    }
  
    // Simplify sixteenths fraction and convert to string
    let fracString = "";
    if (sixteenths % 2 === 0) {
      sixteenths /= 2;
      if (sixteenths % 2 === 0) {
        sixteenths /= 2;
      }
    }
    let fracArray = []
    if (sixteenths !== 0) {
      fracString = (sixteenths === 1) ? "1/16\"" : (sixteenths === 2) ? "1/8\"" : (sixteenths === 3) ? "3/16\"" : (sixteenths === 4) ? "1/4\"" : (sixteenths === 5) ? "5/16\"" : (sixteenths === 6) ? "3/8\"" : (sixteenths === 7) ? "7/16\"" : (sixteenths === 8) ? "1/2\"" : (sixteenths === 9) ? "9/16\"" : (sixteenths === 10) ? "5/8\"" : (sixteenths === 11) ? "11/16\"" : (sixteenths === 12) ? "3/4\"" : (sixteenths === 13) ? "13/16\"" : (sixteenths === 14) ? "7/8\"" : "15/16\"";
      fracArray = (sixteenths === 1) ? [1,16] : (sixteenths === 2) ? [1,8] : (sixteenths === 3) ? [3,16] : (sixteenths === 4) ? [1,4] : (sixteenths === 5) ? [5,16] : (sixteenths === 6) ? [3,8] : (sixteenths === 7) ? [7,16] : (sixteenths === 8) ? [1,2] : (sixteenths === 9) ? [9,16] : (sixteenths === 10) ? [5,8] : (sixteenths === 11) ? [11,16] : (sixteenths === 12) ? [3,4] : (sixteenths === 13) ? [13,16] : (sixteenths === 14) ? [7,8] : [15,16];
    }
  
    return {
      ft: feet,
      inch: inches,
      frac: fracArray,
    };
  }
const dx = d.endX - d.startX;
const dy = d.endY - d.startY;
const magnitude = Math.sqrt(dx * dx + dy * dy);
const dirX = dx / magnitude;
const dirY = dy / magnitude;

const arrowSize = 10;
const newStartX = d.startX + dirX * arrowSize;
const newStartY = d.startY + dirY * arrowSize;
const newEndX = d.endX - dirX * arrowSize;
const newEndY = d.endY - dirY * arrowSize;

let boxWidthEm = 3 * boxScale; // in em
let boxHeightEm = 16 * boxScale; // in px
// Assume 1em is roughly 16px
let boxWidthPx = boxWidthEm * 16; // in px

const getPathData = (coordinates) => {
  let pathData = `M ${coordinates[0].x} ${coordinates[0].y}`;
  for(let i=1; i<coordinates.length; i++){
      pathData += ` L ${coordinates[i].x} ${coordinates[i].y}`;
  }
  return pathData;
}

const calculateBoundingBoxCenter = (points) => {
  let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
  points.forEach(point => {
    minX = Math.min(minX, point.x);
    maxX = Math.max(maxX, point.x);
    minY = Math.min(minY, point.y);
    maxY = Math.max(maxY, point.y);
  });
  return {
    x: (minX + maxX) / 2,
    y: (minY + maxY) / 2,
  };
};

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// MOUSE INTERACTION HANDLERS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
  const handleStampClick = () => {
  if (chosenToolOption == "trashProductStamp") {
    trashStamp(d.id)
  }else if (chosenToolOption == "" && chosenProduct==null) {
    if (selectedStampIndexes.includes(d.id)) {
      setSelectedStampIndexes(selectedStampIndexes.filter(id => id !== d.id)); // Remove the matching id
    } else {
      setSelectedStampIndexes([...selectedStampIndexes, d.id])
    }
    
  }
}

  const handleMouseEnter = () => {
    if (chosenToolOption == "trashProductStamp") {
      setHoverToggle(true)
    } else if (chosenToolOption == "" && chosenProduct==null) {
      setHoverStampData(d)
      // setMessageBoardToggle(true)
    }
  }
  const handleMouseExit = () => {
    if (chosenToolOption == "trashProductStamp") {
      setHoverToggle(false)
    } else if (chosenToolOption == "" && chosenProduct==null) {
      setHoverStampData(null)
      // setMessageBoardToggle(true)
    }
  }

  

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const MessageBoard = () => {
  if (messageBoardToggle === true) return (
    <foreignObject x={d.x - 400} y={d.y - 250} width="50em" height="140em">
      <div style={{
        backgroundColor: "rgba(255,255,255,1)",
        border: "0.8em solid #000", // Change this color as per your need
        borderRadius: "10px",
        padding: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
        flexDirection: "column-reverse",
        width: "100%",
        boxSizing: "border-box",
      }}>
        {/* Add your content here */}
        <div style={{fontSize:"4em",fontWeight:"bold"}}>{stampTitle}</div>
        <div style={{fontSize:"3em",fontWeight:"bold"}}>Multiplier: {d.multiplier}</div>
      </div>
    </foreignObject>
  )
}
const MessageBoardLine = () => {
  if (messageBoardToggle === true) {
    // Define the desired width and height of the foreignObject
    const width = 450; // Change these to desired values
    const height = 300; // Change these to desired values

    return (
      <foreignObject x={d.startingPoint.x} y={d.startingPoint.y} width={width} height={height}>
        <div style={{
          backgroundColor: "rgba(255,255,255,1)",
          border: "0.8em solid #000", // Change this color as per your need
          borderRadius: "10px",
          padding: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
          flexDirection: "column-reverse",
          width: "100%",
          boxSizing: "border-box",
        
        }}>
          {/* Add your content here */}
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>LF: {d.distanceFT.ft}</div>
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>{stampTitle}</div>
          <div style={{ fontSize: "3em", fontWeight: "bold" }}>Multiplier: {d.multiplier}</div>
        </div>
      </foreignObject>
    );
  }
}
const MessageBoardArea = () => {
  if (messageBoardToggle === true) {
    // Calculate the x and y coordinates and width and height for the centering
    const centerX = Math.min(d.startX, d.endX) + (Math.abs(d.startX - d.endX) / 2);
    const centerY = Math.min(d.startY, d.endY) + (Math.abs(d.startY - d.endY) / 2);
    const rectWidth = Math.abs(d.startX - d.endX);
    const rectHeight = Math.abs(d.startY - d.endY);

    // Define the desired width and height of the foreignObject
    const width = 450; // Change these to desired values
    const height = 300; // Change these to desired values

    // Compute the top-left position of the foreignObject
    const x = centerX - (width / 2);
    const y = centerY - (height / 2);

    return (
      <foreignObject x={x} y={y} width={width} height={height}>
        <div style={{
          backgroundColor: "rgba(255,255,255,1)",
          border: "0.8em solid #000", // Change this color as per your need
          borderRadius: "10px",
          padding: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
          flexDirection: "column-reverse",
          width: "100%",
          boxSizing: "border-box",
        }}>
          {/* Add your content here */}
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>SQFT: {d.areaFT.ft}</div>
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>{stampTitle}</div>
          <div style={{ fontSize: "3em", fontWeight: "bold" }}>Multiplier: {d.multiplier}</div>
        </div>
      </foreignObject>
    );
  }
}

const MessageBoardPolygon = () => {
  if (messageBoardToggle === true) {
    // Define the desired width and height of the foreignObject
    const width = 450; // Change these to desired values
    const height = 300; // Change these to desired values

    // Compute the top-left position of the foreignObject
    const centerCoords = calculateBoundingBoxCenter(d.path);

    return (
      <foreignObject x={centerCoords.x} y={centerCoords.y} width={width} height={height}>
        <div style={{
          backgroundColor: "rgba(255,255,255,1)",
          border: "0.8em solid #000", // Change this color as per your need
          borderRadius: "10px",
          padding: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
          flexDirection: "column-reverse",
          width: "100%",
          boxSizing: "border-box",
        }}>
          {/* Add your content here */}
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>SQFT: {d.areaFT.ft}</div>
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>{stampTitle}</div>
          <div style={{ fontSize: "3em", fontWeight: "bold" }}>Multiplier: {d.multiplier}</div>
        </div>
      </foreignObject>
    );
  }
  
}


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// PRIMARY RETURN
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
if (pageNum == d.pageNum) {
  switch (d.shape) {
    case "circle":
      return(
      <g>
        {MessageBoard()}
      <circle
        onMouseEnter={() => {handleMouseEnter()} }
        onMouseLeave={() => {handleMouseExit()}}
        className={stampClass}
        onClick={handleStampClick}
        cx={d.x} cy={d.y} r={50} 
        stroke={tinycolor(d.color).setAlpha(0.5).toRgbString()} 
        fill={tinycolor(d.color).setAlpha(0.2).toRgbString()}
        strokeWidth={(8 * 2)} />
      </g>)
    case "square":
      return(
      <g>
        {MessageBoard()}
        <rect
        onMouseEnter={() => {handleMouseEnter()} }
        onMouseLeave={() => {handleMouseExit()}}
        className={stampClass}
        onClick={handleStampClick}
        x={d.x - 50} y={d.y - 50} width={100} height={100}
        stroke={tinycolor(d.color).setAlpha(0.5).toRgbString()} 
        fill={tinycolor(d.color).setAlpha(0.2).toRgbString()}
        strokeWidth={(8 * 2)} />
        
        </g>
    )
    case "diamond":
      return(<g>
        {MessageBoard()} <polygon
        onMouseEnter={() => {handleMouseEnter()} }
        onMouseLeave={() => {handleMouseExit()}}
        className={stampClass}
        onClick={handleStampClick}
        points={`${d.x},${d.y-50} ${d.x-50},${d.y} ${d.x},${d.y+50} ${d.x+50},${d.y}`}
        stroke={tinycolor(d.color).setAlpha(0.5).toRgbString()} 
        fill={tinycolor(d.color).setAlpha(0.2).toRgbString()}
        strokeWidth={(8 * 2)} /></g>
    )
    case "octagon":
      const radius = 50;
      const points = Array.from({length: 8}, (_, i) => {
        const angle = i * Math.PI / 4;
        return `${d.x + radius * Math.cos(angle)},${d.y + radius * Math.sin(angle)}`;
      });
      return(<g>
        {MessageBoard()}
        <polygon
          onClick={handleStampClick}
          onMouseEnter={() => {handleMouseEnter()} }
          onMouseLeave={() => {handleMouseExit()}}
          className={stampClass}
          points={points.join(' ')}
          stroke={tinycolor(d.color).setAlpha(0.5).toRgbString()} 
          fill={tinycolor(d.color).setAlpha(0.2).toRgbString()}
          strokeWidth={(8 * 2)} /></g>
      )
    case "triangle":
      return(<g>
        {MessageBoard()}<polygon
        onMouseEnter={() => {handleMouseEnter()} }
        onMouseLeave={() => {handleMouseExit()}}
        className={stampClass}
        onClick={handleStampClick}
        points={`${d.x},${d.y-50} ${d.x-50},${d.y+50} ${d.x+50},${d.y+50}`}
        stroke={tinycolor(d.color).setAlpha(0.5).toRgbString()} 
        fill={tinycolor(d.color).setAlpha(0.2).toRgbString()}
        strokeWidth={(8 * 2)} /></g>)
    case "areaBox":
      return(
      <g>
        {MessageBoardArea()}
        <rect
          onClick={handleStampClick}
          onMouseEnter={() => { handleMouseEnter()} }
          onMouseLeave={() => {handleMouseExit()}}
          className={stampClass}
          x={Math.min(d.startX, d.endX)}
          y={Math.min(d.startY, d.endY)}
          width={Math.abs(d.startX - d.endX)}
          height={Math.abs(d.startY - d.endY)}
          fill={d.color}
          fillOpacity={0.3}
          />
        </g>
      )
    case "polygon":
      return(
        <g>
          {MessageBoardPolygon()}
          <polygon
          onClick={handleStampClick}
          onMouseEnter={() => { handleMouseEnter()} }
          onMouseLeave={() => {handleMouseExit()}}
          className={stampClass}
          points={d.path.map(p => `${p.x},${p.y}`).join(' ')}
          fill={d.color}
          fillOpacity={0.3}
          stroke={d.color}
          strokeWidth={10}
          />
        </g>
      )
    case "line":
      return(
        <g 
          onClick={handleStampClick}
          onMouseEnter={() => { handleMouseEnter()} }
          onMouseLeave={() => {handleMouseExit()}}
          className={`measures ${hoverToggle}`}>
            {MessageBoardLine()}
          <circle 
            cx={d.startingPoint.x} 
            cy={d.startingPoint.y} 
            r={d.size * 1.4} 
            stroke={d.color} 
            fill="transparent"
            strokeWidth={d.size * 1}
          />
          <path 
              d={getPathData(d.path)}
              stroke={d.color}
              fill="none"
              strokeWidth={d.size}
              className={stampClass}
          />
          <circle 
            cx={d.endingPoint.x} 
            cy={d.endingPoint.y} 
            r={d.size * 1.4} 
            stroke={d.color} 
            fill="transparent"
            strokeWidth={d.size * 1}
          />
        </g>
    )
  }
}


}
export default Stamps