import {BsPersonCircle,
  BsFillEnvelopeFill,
  BsFillHouseGearFill,
  BsFillRocketTakeoffFill,
  BsCreditCardFill,
  BsQuestionCircleFill,
  BsLightningChargeFill,
  BsPersonFillLock,
  BsPerson,
  BsBookHalf,
} from 'react-icons/bs'
import checkMark from '../assets/art/volt_2.4.svg'
import { useNavigate } from 'react-router-dom';
const NavBar = () => {

  const navigate = useNavigate();

  const navChange = (route) => {
    if (route == "") {
      navigate("/")
    } else {
      const adjustedRoute = '/' + route + '/'
      navigate(adjustedRoute)
    }
  }

  

  return(
  <div className='NavBar'>
          
          <div onClick={()=>{navChange('')}} className='NavButtonBox icon'>
            <img className='NavButtonLogo' src={checkMark} alt="Logo" />
          </div>
          <div onClick={()=>{navChange('catalog')}}  className='NavButtonBox'>
            <BsBookHalf style={{color:"00aeef"}} className='NavButtonIcon' />
            <div className='NavButtonText'>&nbsp;Catalog</div>
          </div>
          
          {/* <div onClick={()=>{navChange('about')}}  className='NavButtonBox'>
            <div className='NavButtonText'>About</div>
          </div> */}

          <div onClick={()=>{navigate('/plan-room/app', { state: { myData: "Riverbend", isDemo: true } })}} className='NavButtonBox' style={{width:"20%"}}>
            <BsLightningChargeFill style={{color:"#ffde17"}} className='NavButtonIcon' />
            <div className='NavButtonText'>Free Demo</div>
          </div>
          <div onClick={()=>{navChange('login')}}  className='NavButtonBox'>
            <BsPerson style={{color:"00aeef", border:"2px solid white", borderRadius:50}} className='NavButtonIcon' />
            <div className='NavButtonText'>Login</div>
          </div>
        </div>
  )
}

export default NavBar