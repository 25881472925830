import NavBar from "./NavBar"
import './SplashStyles.css'
import Footer from "./Footer"
import invoice from '../assets/invoice.png'

const About = () => {
  return(
  <div className="App">
    <NavBar />

    
    <Footer />
  </div>
  )
}

export default About