import NavBar from "./NavBar"
import arlIcon from '../assets/art/arlicon.png'

const Support = () => {

  return(
    <div className="App">
        <div className="App-header">
        <div className='NavBar' style={{justifyContent:"flex-start", backgroundColor:"#1c1c1c"}}>
          <div className='NavButtonBox icon' >
            <img className='NavButtonLogo' src={arlIcon} alt="Logo" />
          </div>
          <div style={{width:"5em"}} />
          <div style={{fontSize:"1.6em"}}>ARL Support Page</div>
        </div>

        {/* Main Support Section */}
        <div className='pane-wrapper' style={{height:"120px", backgroundColor:"#1c1c1c"}}>
          <h1 style={{color:"#fff"}}>Get support for ARL</h1>
          <h3 style={{color:"#fff"}}>Your Guide to ARL Troubleshooting and FAQs</h3>
        </div>

        <div className='pane-wrapper' style={{height:"500px", backgroundColor:"#fff"}}>
          <div className='FAQs-section' style={{display:"flex",flexDirection:"column",textAlign:"left"}}>
            <h2>Frequently Asked Questions</h2>
            <p>Find answers to common questions about ARL, its features, and how to use them.</p>
            <h3>Q: How do I use ARL?</h3>
            <p>A: Getting started with ARL is easy! First, download the app and create your account. Once you're in, explore the Traits section to see different areas like Fitness, Cooking, and more. To level up a Trait, simply engage in related real-life activities and share them on ARL through Log, Camera, or Timeline posts.</p>
            <h3>Q: Can I play alone?</h3>
            <p>A: Yes, to a certain extent. Every Experience can be made private. And you can make your profile private and unsearchable!</p>
            <h3>Q: I encountered an offensive user, what do I do?</h3>
            <p>A: You can report their posts, and by doing so our team will see if the user should be banned from the platform. Bare in mind that ARL is self-moderating, and without any user reports no one can be punished.</p>
          </div>
        </div>

        <div className='pane-wrapper' style={{height:"500px", backgroundColor:"#1c1c1c",}}>
          <div className='Troubleshooting-section' style={{display:"flex",flexDirection:"column",textAlign:"left"}}>
            <h2 style={{color:"#fff"}}>Troubleshooting</h2>
            <p style={{color:"#fff"}}>Encountering issues? Check out our troubleshooting guide for quick fixes and tips.</p>
            <h3 style={{color:"#fff"}}>My app is slow, what gives?</h3>
            <p style={{color:"#fff"}}>ARL is still very new, and was made by a newbie in tech. Please be patient with us while we continue to make the app better and better.</p>
            <h3 style={{color:"#fff"}}>I can't find a user when I search?</h3>
            <p style={{color:"#fff"}}>It is possible that they have blocked you, or that their profile is public. Please respect their privacy!</p>
            <h3 style={{color:"#fff"}}>The app often crashes?</h3>
            <p style={{color:"#fff"}}>Please contact support and we will be happy to help, maybe you'll even help us by exposing a bug!</p>
          </div>
        </div>

        <div className='pane-wrapper' style={{height:"260px", backgroundColor:"#fff"}}>
          <div className='ContactSupport-section'>
            <h2>Contact Support</h2>
            <p>Can't find what you're looking for? Get in touch with our support team.</p>
            <h3>Github Page</h3>
            <p>Head over to <a href={"https://Github.com/SebTheRed/ARL"}>https://Github.com/SebTheRed/ARL</a> to view the open-source code, and to submit tickets or requests there!</p>
            <h3>Support Email:</h3>
            <p>Reach out to <a href={"mailto:app4reallife@gmail.com"}>app4reallife@gmail.com</a> for support.</p>
            {/* Add contact form or information here */}
          </div>
        </div>

        <div className='pane-wrapper' style={{height:"200px", backgroundColor:"#1c1c1c"}}>
          <div className='Footer-section'>
            <p style={{color:"#fff"}}>© 2023 Volt Applications - All rights reserved</p>
          </div>
        </div>
        </div>
    </div>
  )
}

export default Support