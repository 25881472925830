import { useEffect,useState } from "react"
import {BsWrenchAdjustableCircleFill,BsGearFill,BsGrid3X3} from 'react-icons/bs'
import {TbWindow,TbRulerMeasure} from 'react-icons/tb'
import polyArea2 from './poly-area2.svg'
import {LuStamp} from 'react-icons/lu'
const ProductTile = ({
  chosenItemsList,
  setProductEditState,
  productEditState,
  countState,
  deleteProductFromList,
  d,
  i,
  setChosenProduct,
  chosenProduct,
  productCollapse,
  iconRegistry,
  forceUpdate,
  setChosenToolOption,
  removalState}) => {
  const [hover,setHover] = useState(false)
  const [selectedColor, setSelectedColor] = useState(null);
  const [countNum,setCountNum] = useState(0)

  useEffect(()=>{
    if (i == chosenProduct) {
      setSelectedColor(d.color)
    } else {
      setSelectedColor(null)
    }
  },[chosenProduct,forceUpdate])

  useEffect(()=>{
    if (countState && countState.get(d.id) != undefined) {
      console.log(d.title, countState.get(d.id))
      setCountNum(countState.get(d.id))
    } else {
      setCountNum(0)
    }
  },[countState, chosenItemsList, forceUpdate]) // Add forceUpdate here


const ToolIcon = () => {
  switch(d.type){
    case "stamp":
      return(<LuStamp/>)
    case "line":
      return(<TbRulerMeasure/>)
    case "boxArea":
      return(<BsGrid3X3/>)
    case "drawArea":
      return( <img style={{height:"1em"}} src={polyArea2} />)
    default:
      return(<LuStamp/>)
  }
}

  return(
    <>
    {productCollapse == false && (
      <div style={{
        borderBottom:"2px solid white",
        boxSizing:"border-box",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        backgroundColor: selectedColor ? selectedColor : (hover ? `${d.color}` : 'transparent'),
        cursor:"pointer",
      }}
      onMouseEnter={()=> setHover(true)}
      onMouseLeave={()=> setHover(false)}
      onClick={()=> {
       
        if(removalState == true){
          console.log(d.id)
          deleteProductFromList(d.id,i)
        } else if (chosenProduct == i){setChosenProduct(null)
        }else{ 
          setChosenToolOption("")
          setChosenProduct(i)
        }
      }}
      >
        <div style={{width:"1.3em",height:"1.2em",fontSize:"1.5em",backgroundColor:`transparent`,display:"flex",alignItems:"center",justifyContent:"center",border:"0px solid black",boxSizing:"border-box"}}>
          <ToolIcon />
        </div>
        <div style={{color:`${d.color}`,width:"1.3em",height:"1.2em",fontSize:"1.8em",backgroundColor:`#272727`,display:"flex",alignItems:"center",justifyContent:"center",border:"0px solid black",boxSizing:"border-box"}}>
          {iconRegistry[d.icon]}
        </div>
        
        <div style={{width:".2em"}}></div>
        <div style={{width:"8em",fontSize:"1em"}}>{d.title}</div>
        
        <div style={{width:"3em",fontSize:"0.8em"}}>{countNum}</div>
        <div style={{width:"5em",fontSize:"0.8em"}}>{(d.cost * countNum).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
        <div style={{width:"1em",fontSize:"1em"}} className='click' onClick={()=>{
          console.log(d)
          setProductEditState({bool:true,prod:d,index:i})
          }}>
          <div style={{fontSize:"1.2em"}}><BsGearFill /></div>
        </div>
      <div style={{width:".3em"}}></div>
      </div>
    )}
    




    {productCollapse == true && (
      <div style={{
        boxSizing:"border-box",
        display:"flex",
        flexDirection:"column",
        height: "2.2em",
        alignItems:"center",
        backgroundColor: selectedColor ? selectedColor : (hover ? `${d.color}` : 'transparent'),
        cursor:"pointer"
      }}
      onMouseEnter={()=> setHover(true)}
      onMouseLeave={()=> setHover(false)}
      onClick={()=> {
        if (chosenProduct == i){setChosenProduct(null)}else{setChosenProduct(i)}
      }}
      >
        <div style={{width:".3em"}}></div>
        <div style={{
        color: chosenProduct === i ? 'white' : `${d.color}`, // update color here
        width:"1.4em",
        height:"1.4em",
        fontSize:"1.8em",
        backgroundColor: chosenProduct === i ? 'rgba(50, 50, 50,0.0)' : `rgba(50, 50, 50,0.5)`, // update color here
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        border: chosenProduct === i ? '0px solid white' : "2px solid black", // update border color here
        boxSizing:"border-box"}}>
        {iconRegistry[d.icon]}
        </div>
      </div>
    )}
    
    </>
    )
}

export default ProductTile