import {useEffect,useState,useRef} from 'react'
import {BsFillCircleFill,BsFillDiamondFill,BsArrowLeftRight,BsFillOctagonFill,BsFillSquareFill,BsFillTriangleFill,BsGrid3X3} from 'react-icons/bs'
import {
  BsFillDoorOpenFill,
  BsSafe2Fill,
  BsBoxFill,
  BsBox2Fill,
  BsDropbox,
  BsFillDropletFill,
  BsDropletHalf,
  BsFillHouseFill,
  BsFillLampFill,
  BsModemFill,
  BsPcDisplay,
  BsMotherboardFill,
  BsProjectorFill,
  BsFillLightbulbFill,
  BsFillLightningChargeFill,
  BsFillLightningFill,
  BsBatteryCharging,
  BsFillMagnetFill,
  BsFillPlugFill,
  BsEthernet,
  BsEvStationFill,
  BsOutlet,
  BsFileLock2Fill,
  BsFan,
  BsGearWideConnected,
  BsGearWide,
  BsNutFill,
  BsHammer,
  BsTools,
  BsFillWrenchAdjustableCircleFill,
  BsConeStriped,
  BsTrash3Fill,
  BsFillTagFill,
  BsPostageFill,
  BsPostage,
  BsPostageHeartFill,
  BsHourglassSplit,
  BsFillTreeFill,
  BsTree,
  BsFillPinFill,
  BsCameraFill,
  BsCoin,
  BsRadioactive,
  BsSuitSpadeFill,
  BsSuitClubFill,
  BsSuitHeartFill,
  BsSuitDiamondFill,
  BsFire,
  BsGiftFill,
  BsPiggyBankFill,BsFillPlusSquareFill,
} from 'react-icons/bs'
import {
  MdKey,
  MdVpnKey,
  MdEco,
  MdEnergySavingsLeaf,
  MdStars,
  MdStarRate,
  MdGesture,
  MdWaves,
  MdAir,
  MdCable,
  MdPriceCheck,
  MdWidgets,
  MdFormatPaint,
  MdBrush,
  MdGridOn,
  MdGrain,
  MdReceiptLong,
  MdDesignServices,
  MdLocalLaundryService,
  MdLocalHotel,
  MdLocalFlorist,
  MdLocalAtm,
  MdPlumbing,
  MdAcUnit,
  MdMicrowave,
  MdLight,
  MdHive,
  MdRecycling,

} from 'react-icons/md'
import {TbWindow,TbRulerMeasure} from 'react-icons/tb'
import {LuStamp} from 'react-icons/lu'
import {MdTimeline} from 'react-icons/md'
import polyArea2 from '../HeaderStuff/poly-area2.svg'
const NewProductPopup = ({
  submitNewProduct,
  chosenItemsList,
  setChosenItemsList,
  setProductAddingState,
  iconRegistry,
  productCatalog,
  addFromCatalog,
  productEditState,
  setProductEditState,
  deleteProductFromList,
  updateProduct,
  setForceUpdate,
}) => {
  
  const [chosenAddType,setChosenAddType] = useState("stampNew")
  const [chosenStampToolType,setChosenStampToolType] = useState("")
  const [editProductData,setEditProductData] = useState(null)
  const [chosenColor,setChosenColor] = useState("#0000ff")
  const [chosenIcon,setChosenIcon] = useState({})
  const [chosenIconString,setChosenIconString] = useState("BsBoxFill")
  const [chosenShape,setChosenShape] = useState("circle")
  const [chosenComponent,setChosenComponent] = useState(null)
  const titleRef = useRef()
  const costRef = useRef()
  const colorRef = useRef(null)



  useEffect(()=>{
    if (productEditState.bool == true) {
      setEditProductData(productEditState.prod)
      setChosenAddType("editStamp")
      setChosenColor(productEditState.prod.color)
      setChosenShape(productEditState.prod.shape)
      setChosenIcon(iconRegistry[productEditState.prod.icon])
      setChosenIconString(productEditState.prod.icon)
      switch(productEditState.prod.shape){
        case"circle": setChosenComponent(<BsFillCircleFill />) 
        break;
        case"square": setChosenComponent(<BsFillSquareFill />) 
        break;
        case"diamond": setChosenComponent(<BsFillDiamondFill />) 
        break;
        case"octagon": setChosenComponent(<BsFillOctagonFill />) 
        break;
        case"triangle": setChosenComponent(<BsFillTriangleFill />) 
        break;
      }
    } else {
      setChosenComponent(<BsFillCircleFill />)
    }
  },[productEditState])


  const isFormValid = (assemblyCheck) => {
    // get all the values from the assembly object
    const values = Object.values(assemblyCheck);

    // check if every value is not null, not undefined and not an empty string
    return values.every(value => value !== null && value !== undefined && value !== "");
}
  const compileForm = () => {
    let assembly = {
      materials:[],
      size: 4,
      type: chosenStampToolType,
      title:titleRef.current.value,
      cost:parseFloat(costRef.current.value),
      color:chosenColor,
      shape:chosenShape,
      icon:chosenIconString,
      stampTool:chosenStampToolType,
      
    }
    let assemblyCheck = {
      type: chosenStampToolType,
      title:titleRef.current.value,
      cost:costRef.current.value,
    }
    if(isFormValid(assemblyCheck)) {
      submitNewProduct(assembly)
    } else {
      console.error("COMPLETE FORM BEFORE SUBMIT!@@")
    }
    
    
  }
  const compileEdit = async () => {
    let assembly = {
      materials:[],
      size: 4,
      type: productEditState.prod.type,
      title:titleRef.current.value,
      cost:parseFloat(costRef.current.value),
      color:chosenColor,
      shape:chosenShape,
      icon:chosenIconString,
      stampTool:chosenStampToolType,
      
    }
    let assemblyCheck = {
      title:titleRef.current.value,
      cost:costRef.current.value,
    }
    if(isFormValid(assemblyCheck)) {
      // If it's valid, call updateProduct with assembly and productEditState.prod.id
      await updateProduct(assembly, productEditState.prod.id);
    } else {
      console.error("COMPLETE FORM BEFORE SUBMIT!")
    }
  }
  const assembleForm = (d) => {
    submitNewProduct(d)
    console.log(d)
  }

  const css = {
    topButtons:{
      width:"50%",
      fontSize: '0.95em',
      alignItems:"center",
      display:"flex",
      justifyContent:"center",
      cursor:"pointer",
      borderRight:"2px solid white",
      color:"white"
    },
    smallText:{
      fontSize:"0.5em"
    },
    toolTypeButtons:{
      width:"46%",
      height:"50%",
      boxSizing:"border-box",
      display:"flex",
      flexDirection:"row",
      border:"2px solid white",
      margin:"0.1em",
      alignItems:"center",
      justifyContent:"flex-start",
      paddingLeft:"0.2em",
      cursor:"pointer",
      userSelect:"none",
    },
    toolTypeFont:{
      fontSize:"0.6em",
      marginLeft:"1em"
    },
    buttonsWrapper:{
      boxSizing:"border-box",
      display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly",
      width:"10em"
    },
    buttonsFrame: {
      display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly",
      backgroundColor:"#ffffff",
      height:"1.4em",
      cursor:"pointer",
      borderRadius:"4px"
      
    },
    noFrameBend:{
      borderRadius:"0"
    },
    buttonColor:{
      height:"1.1em",
      width:"1.1em",
      boxSizing:"border-box",
      border:"1px solid black",
    },
    buttonIcon:{
      height:"1.1em",
      width:"1.1em",
      boxSizing:"border-box",
      border:"1px solid black",
      fontSize:"1.1em",
      backgroundColor:"black",
      cursor:"pointer",
    },
    optionBox:{
      width:"100%",
      margin:"0.5em",
      marginBottom:"0.25em",
      display:"flex",
      alignItems:"flex-end",
      border:"2px solid gray",
      borderRadius:".2em",
    }
  }


  const IconOption = ({icon, index, iconString}) => {
    if (chosenIcon === icon) {return(
      <div className='buttonsFrame' onClick={()=>{setChosenIcon({icon})
      setChosenIconString(iconString)}} style={{...css.buttonsFrame,...css.noFrameBend,width:"1.4em",color:"yellow"}}><div className='buttonIcon' style={{...css.buttonIcon}}>{icon}</div></div>
    )} else {
      return(
        <div className='buttonsFrame' onClick={()=>{setChosenIcon(icon)
        setChosenIconString(iconString)}} style={{...css.buttonsFrame,...css.noFrameBend,width:"1.4em"}}><div className='buttonIcon' style={{...css.buttonIcon}}>{icon}</div></div>
      )
    }
    
  } 

switch (chosenAddType) {
  case "stampNew" :
    return(
      <div style={{
        position: 'fixed',
        zIndex:"100",
        top: 0, bottom: 0, left: 0, right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
        <form style={{
          backgroundColor: '#292929',
          marginTop:"0.5em",
          width: "30em",
          display: "flex",
          flexDirection: "row",
          flexWrap:"wrap",
          fontSize: "1.5em",
          alignItems: "center",
          borderRadius: "1em",
          filter: `drop-shadow(0px 0px 20px ${chosenColor})`,
          color: "white",
          border:"2px solid white",
          overflow:"auto"
        }}>
          <div style={{width:"100%",display:"flex",flexDirection:"row",borderBottom:"4px solid white"}}>
              <div style={{...css.topButtons,backgroundColor: chosenAddType === "stampNew" ? "white" : "transparent",color: chosenAddType === "stampNew" ? "black" : "white"}} onClick={()=>setChosenAddType("stampNew")}>Create</div>
              <div style={{...css.topButtons,backgroundColor: chosenAddType === "stampCatalog" ? "white" : "transparent",color: chosenAddType === "stampCatalog" ? "black" : "white",}} onClick={()=>setChosenAddType("stampCatalog")}>Catalog</div>
          </div>
          <div style={{flexDirection:"row",...css.optionBox}}>
            <div style={{fontSize: "1.2em", margin: "0.2em"}}>Create a product.</div>
            <div style={{fontSize: "0.7em", margin: "0.6em"}}>Add a tool to your Estimating toolbar.</div>
          </div>
          
          <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"55%",height:"8em"}}>
          <div style={{fontSize: "1em", margin: "0.2em"}}>Set the title & cost:</div>
            <div style={{...css.buttonsWrapper,margin:"0 0.2em"}}>
              <input type="text" maxLength={20} ref={titleRef} style={{fontSize: "0.8em",width:"7em"}}></input>
              <div style={{marginLeft: "0.5em"}}>$</div>
              <input ref={costRef} type="number" style={{fontSize: "0.8em",width:"3em"}}></input>
            </div>
          <div style={{fontSize: "1em", margin: "0 0.2em"}}>Set the type of tool:</div>
              <div style={{margin:"0.2em",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                <div style={{
                  ...css.toolTypeButtons,
                  backgroundColor:chosenStampToolType=="stamp" ? "white" : "transparent",
                  color:chosenStampToolType=="stamp" ? "black" : "white"
                }}
                 onClick={()=>{setChosenStampToolType('stamp')}}>
                  <LuStamp />
                  <div style={css.toolTypeFont}>Stamp Counter</div> 
                </div>
                <div style={{
                  ...css.toolTypeButtons,
                  backgroundColor:chosenStampToolType=="line" ? "white" : "transparent",
                  color:chosenStampToolType=="line" ? "black" : "white"
                }} onClick={()=>{setChosenStampToolType('line')}}>
                <TbRulerMeasure />
                <div style={css.toolTypeFont}>Line Counter</div>
                </div>
                <div style={{
                  ...css.toolTypeButtons,
                  backgroundColor:chosenStampToolType=="boxArea" ? "white" : "transparent",
                  color:chosenStampToolType=="boxArea" ? "black" : "white"
                }} onClick={()=>{setChosenStampToolType('boxArea')}}>
                  <BsGrid3X3 />
                <div style={css.toolTypeFont}>Box Area Counter</div>
                </div>
                <div style={{
                  ...css.toolTypeButtons,
                  backgroundColor:chosenStampToolType=="drawArea" ? "white" : "transparent",
                  color:chosenStampToolType=="drawArea" ? "black" : "white"
                }} onClick={()=>{setChosenStampToolType('drawArea')}}>
                <img style={{height:"1em", filter:chosenStampToolType=="drawArea" ? "invert(1)":"invert(0)"}} src={polyArea2} />
                <div style={css.toolTypeFont}>Draw Area Counter</div>
                </div>
            </div>
          
          </div>
          <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"35%",height:"8em",justifyContent:"space-evenly"}}>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the color:</div>
            <div style={{...css.buttonsWrapper}}>
              <div  style={{...css.buttonsFrame,width:"2em"}}><input value={chosenColor} ref={colorRef} onChange={()=>{setChosenColor(colorRef.current.value)}} type={"color"} style={{border:"none",cursor:"pointer"}}></input></div>
              <div onClick={()=>{setChosenColor("#ff0000")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ff0000"}}></div></div>
              <div onClick={()=>{setChosenColor("#ffa500")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffa500"}}></div></div>
              <div onClick={()=>{setChosenColor("#ffff00")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffff00"}}></div></div>
              <div onClick={()=>{setChosenColor("#1be600")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#1be600"}}></div></div>
              <div onClick={()=>{setChosenColor("#0000ff")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#0000ff"}}></div></div>  
            </div>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the shape:</div>
          <div style={{...css.buttonsWrapper, width:"100%"}}>
            <div style={{fontSize:"1.4em",color:`${chosenColor}`}}>{chosenComponent}</div>
            
            <div onClick={() => { setChosenShape("circle")
          setChosenComponent(<BsFillCircleFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
              <BsFillCircleFill />
            </div>
            <div onClick={() => { setChosenShape("square")
          setChosenComponent(<BsFillSquareFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillSquareFill />
            </div>
            <div onClick={() => { setChosenShape("diamond")
          setChosenComponent(<BsFillDiamondFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillDiamondFill />
            </div>
            <div onClick={() => { setChosenShape("octagon")
          setChosenComponent(<BsFillOctagonFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillOctagonFill />
            </div>
            <div onClick={() => { setChosenShape("triangle")
          setChosenComponent(<BsFillTriangleFill />) }} style={{...css.buttonsFrame, width: "1.4em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillTriangleFill />
            </div>
            
          </div>
          </div>
          
          <div style={{marginTop: "0.1em"}}></div>
          <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"100%",height:"6.5em",justifyContent:"flex-start"}}>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the icon:</div>
            <div style={{width:"97%",boxSizing:"border-box",display:"flex",flexDirection:"row",flexWrap:"wrap", height: "4em",overflow:"auto",boxSizing:"border-box",border:"2px solid white",margin:"0 0.5em"}}>
            {Object.keys(iconRegistry).map((iconKey, i) => {
              return(
                <IconOption 
                  index={i} 
                  key={i} 
                  icon={iconRegistry[iconKey]} 
                  iconString={iconKey} 
                />
              );
            })}  
            </div>
          </div>
          <div style={{marginTop: "1em"}}></div>
          <div style={{width:"100%",display:"flex",flexDirection:"row",borderTop:"4px solid white"}}>
              <div style={{...css.topButtons,fontWeight:"bold",backgroundColor:"white",color:"black"}} onClick={compileForm}>Save Product</div>
              <div style={{...css.topButtons,backgroundColor: "transparent"}} onClick={()=>{
              setProductAddingState(false)
              setProductEditState(false)
            }}>Cancel</div>
          </div>
        </form>
      </div>
    )
  case "stampCatalog":
    return(
      <div style={{
        position: 'fixed',
        zIndex:"100",
        top: 0, bottom: 0, left: 0, right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
        <div style={{
          backgroundColor: '#292929',
          marginTop:"0.5em",
          width: "33em",
          display: "flex",
          flexDirection: "column",
          fontSize: "1.5em",
          alignItems: "center",
          borderRadius: "1em",
          filter: `drop-shadow(0px 0px 20px ${chosenColor})`,
          color: "white",
          border:"2px solid white",
          overflow:"auto"
        }}>
          <div style={{width:"100%",display:"flex",flexDirection:"row",borderBottom:"4px solid white"}}>
              <div style={{...css.topButtons,backgroundColor: chosenAddType === "stampNew" ? "white" : "transparent",color: chosenAddType === "stampNew" ? "black" : "white"}} onClick={()=>setChosenAddType("stampNew")}>Create</div>
              <div style={{...css.topButtons,backgroundColor: chosenAddType === "stampCatalog" ? "white" : "transparent",color: chosenAddType === "stampCatalog" ? "black" : "white",}} onClick={()=>setChosenAddType("stampCatalog")}>Catalog</div>
          </div>
          <div style={{fontSize: "1.2em", margin: "0.2em"}}>Add catalog product.</div>
          <div style={{fontSize: ".9em", margin: "0.2em"}}>Click the products you want to use.</div>
          <div style={{marginTop: "0.1em"}}></div>
          <div style={{overflowY:"scroll",marginLeft:"1em", height:"15em", display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
            {productCatalog.map((d,i)=>{
              return(
                <div key={i} style={{
                  border:"2px solid white",
                  boxSizing:"border-box",
                  display:"flex",
                  flexDirection:"row",
                  alignItems:"center",
                  backgroundColor: "black",
                  cursor:"pointer",
                  userSelect:"none",
                }}
                onClick={()=> {
                  addFromCatalog(d)
                }}
                >
                  <div style={{color:`${d.color}`,width:"1.3em",height:"1.2em",fontSize:"1.4em",backgroundColor:`#272727`,display:"flex",alignItems:"center",justifyContent:"center",border:"2px solid black",boxSizing:"border-box"}}>
                    {iconRegistry[d.icon]}
                  </div>
                  <div style={{width:".2em"}}></div>
                  <div style={{width:"12em",fontSize:".8em"}}>{d.title}</div>
                  <div style={{width:"4em",fontSize:"0.8em"}}>{d.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                <div style={{width:".3em"}}></div>
                </div>
              )
            })}
          </div>
          <div style={{marginTop: "1em"}}></div>
          <div style={{width:"100%",display:"flex",flexDirection:"row",borderTop:"4px solid white"}}>
              <div style={{...css.topButtons,fontWeight:"bold",backgroundColor:"white",color:"black"}} onClick={compileForm}>Save Product</div>
              <div style={{...css.topButtons,backgroundColor: "transparent"}} onClick={()=>{
              setProductAddingState(false)
              setProductEditState(false)
            }}>Cancel</div>
          </div>
        </div>
        </div>
    )
    case "editStamp":
      return(
        <div style={{
          position: 'fixed',
          zIndex:"100",
          top: 0, bottom: 0, left: 0, right: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}>
          <form style={{
          backgroundColor: '#292929',
          marginTop:"0.5em",
          width: "30em",
          display: "flex",
          flexDirection: "row",
          flexWrap:"wrap",
          fontSize: "1.5em",
          alignItems: "center",
          borderRadius: "1em",
          filter: `drop-shadow(0px 0px 20px ${chosenColor})`,
          color: "white",
          border:"2px solid white",
          overflow:"auto"
        }}>
          <div style={{flexDirection:"row",...css.optionBox}}>
            <div style={{fontSize: "1.2em", margin: "0.2em"}}>Edit {editProductData.title}</div>
            <div style={{fontSize: "0.7em", margin: "0.6em"}}>Editing a product named:  {editProductData.title}</div>
          </div>
          <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"55%",height:"8em"}}>
          <div style={{fontSize: "1em", margin: "0.2em"}}>Set the type of tool:</div>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the title & cost:</div>
            <div style={{...css.buttonsWrapper,margin:"0.5em"}}>
              <input type="text" maxLength={20} ref={titleRef} defaultValue={`${editProductData.title}`} style={{fontSize: "0.8em",width:"7em"}}></input>
              <div style={{marginLeft: "0.5em"}}>$</div>
              <input ref={costRef} defaultValue={`${editProductData.cost}`} type="number" style={{fontSize: "0.8em",width:"3em"}}></input>
            </div>
          </div>
          <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"35%",height:"8em",justifyContent:"space-evenly"}}>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the color:</div>
            <div style={{...css.buttonsWrapper}}>
              <div  style={{...css.buttonsFrame,width:"2em"}}><input value={chosenColor} ref={colorRef} onChange={()=>{setChosenColor(colorRef.current.value)}} type={"color"} style={{border:"none",cursor:"pointer"}}></input></div>
              <div onClick={()=>{setChosenColor("#ff0000")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ff0000"}}></div></div>
              <div onClick={()=>{setChosenColor("#ffa500")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffa500"}}></div></div>
              <div onClick={()=>{setChosenColor("#ffff00")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffff00"}}></div></div>
              <div onClick={()=>{setChosenColor("#1be600")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#1be600"}}></div></div>
              <div onClick={()=>{setChosenColor("#0000ff")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#0000ff"}}></div></div>  
            </div>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the shape:</div>
          <div style={{...css.buttonsWrapper, width:"100%"}}>
            <div style={{fontSize:"1.4em",color:`${chosenColor}`}}>{chosenComponent}</div>
            
            <div onClick={() => { setChosenShape("circle")
          setChosenComponent(<BsFillCircleFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
              <BsFillCircleFill />
            </div>
            <div onClick={() => { setChosenShape("square")
          setChosenComponent(<BsFillSquareFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillSquareFill />
            </div>
            <div onClick={() => { setChosenShape("diamond")
          setChosenComponent(<BsFillDiamondFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillDiamondFill />
            </div>
            <div onClick={() => { setChosenShape("octagon")
          setChosenComponent(<BsFillOctagonFill />) }} style={{...css.buttonsFrame, width: "1.3em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillOctagonFill />
            </div>
            <div onClick={() => { setChosenShape("triangle")
          setChosenComponent(<BsFillTriangleFill />) }} style={{...css.buttonsFrame, width: "1.4em", fontSize:"1.2em",color:`${chosenColor}`}}>
            <BsFillTriangleFill />
            </div>
            
          </div>
          </div>
          
          <div style={{marginTop: "0.1em"}}></div>
          <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"100%",height:"6.5em",justifyContent:"flex-start"}}>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the icon:</div>
            <div style={{width:"97%",boxSizing:"border-box",display:"flex",flexDirection:"row",flexWrap:"wrap", height: "4em",overflow:"auto",boxSizing:"border-box",border:"2px solid white",margin:"0 0.5em"}}>
            {Object.keys(iconRegistry).map((iconKey, i) => {
              return(
                <IconOption 
                  index={i} 
                  key={i} 
                  icon={iconRegistry[iconKey]} 
                  iconString={iconKey} 
                />
              );
            })}  
            </div>
          </div>
          <div style={{marginTop: "1em"}}></div>
          <div style={{width:"100%",display:"flex",flexDirection:"row",borderTop:"4px solid white"}}>
              <div style={{...css.topButtons,fontWeight:"bold",backgroundColor:"white",color:"black"}} onClick={compileEdit}>Save Edit</div>
              <div style={{...css.topButtons,backgroundColor:"transparent"}} onClick={()=>{
                setProductAddingState(false)
                setProductEditState(false)
              }}>Cancel</div>
              <div style={{...css.topButtons,backgroundColor: 'rgb(161, 0, 0)'}} onClick={(e)=>{
              e.preventDefault()
              setProductEditState(false)
              deleteProductFromList(productEditState.prod.id, 0)
              }}>Delete</div>
          </div>
          </form>
        </div>
      )
}

    
}

export default NewProductPopup