import { useState, useEffect, useRef } from "react";

const StampTrashMessage = ({eviewBoxRef,framePos}) => {

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [type,setType] = useState("")

  const inverseScale = 1/ (framePos.scale * 2.5)

  useEffect(() => {
    const handleMouseMove = (e) => {
      const svg = eviewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      setPosition({ x: svgP.x, y: svgP.y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <svg>
      <filter id="redDropShadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor="red"/>
        </filter>
      <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
        <rect
          width={340} 
          height={100} 
          rx={10} 
          ry={10} 
          fill="rgba(40, 40, 40, 0.95);" 
          stroke="black"
          filter="url(#redDropShadow)"
        />
        <text
          y={40}
          fill="white"
          fontSize="2.2em"
        >
          Click to delete.
        </text>
        <text
          y={80}
          fill="white"
          fontSize="2.2em"
        >
          Press ESC to cancel.
        </text>
      </g>
    </svg>
  );
};

export default StampTrashMessage