import {useState, useEffect, useRef} from 'react'
import {BsFillTrash3Fill,BsFillGearFill,BsTrash3Fill,BsFillCircleFill,BsFillSquareFill,BsFillDiamondFill,BsFillOctagonFill,BsFillTriangleFill} from 'react-icons/bs'
const PinPopup = ({ pin, onSubmit, onCancel,chosenToolOption,setChosenToolOption,setEditingPinIndex }) => {
  const [forceRefresh,setForceRefresh] = useState(false)
  const [notesState, setNotesState] = useState([]);
  const [chosenColor, setChosenColor] = useState();
  const titleRef = useRef()
  const messageRef = useRef()
  const colorRef = useRef(null)

  useEffect(()=>{
    // if (pin) {
    //   console.log(pin)
    //   console.log(pin.notes)
    // setNotesState(pin.notes)
    // }
    if (pin && Array.isArray(pin.notes)){
      console.log(pin)
      console.log(pin.notes)
      setNotesState([...pin.notes].reverse());
    }else {
      setNotesState([])
    }
    if (pin.color) {
      setChosenColor(pin.color)
    } 
    
  },[pin])


  const css = {
    topButtons:{
      width:"50%",
      fontSize: '0.95em',
      alignItems:"center",
      display:"flex",
      justifyContent:"center",
      cursor:"pointer",
      borderRight:"2px solid white",
      color:"white"
    },
    smallText:{
      fontSize:"0.5em"
    },
    toolTypeButtons:{
      width:"46%",
      height:"50%",
      boxSizing:"border-box",
      display:"flex",
      flexDirection:"row",
      border:"2px solid white",
      margin:"0.1em",
      alignItems:"center",
      justifyContent:"flex-start",
      paddingLeft:"0.2em",
      cursor:"pointer",
      userSelect:"none",
    },
    toolTypeFont:{
      fontSize:"0.6em",
      marginLeft:"1em"
    },
    buttonsWrapper:{
      boxSizing:"border-box",
      display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly",
      width:"10em"
    },
    buttonsFrame: {
      display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly",
      backgroundColor:"#ffffff",
      height:"1.4em",
      cursor:"pointer",
      borderRadius:"4px"
    },
    timeStampBox:{
      fontSize:'0.8em',
      width:"4.8em",
      paddingLeft:"0.5em",
      border:"1px solid white",
      borderRadius:"4px",
      

    },
    noFrameBend:{
      borderRadius:"0"
    },
    buttonColor:{
      height:"1.1em",
      width:"1.1em",
      boxSizing:"border-box",
      border:"1px solid black",
    },
    buttonIcon:{
      height:"1.1em",
      width:"1.1em",
      boxSizing:"border-box",
      border:"1px solid black",
      fontSize:"1.1em",
      backgroundColor:"black",
      cursor:"pointer",
    },
    optionBox:{
      width:"100%",
      margin:"0.5em",
      marginBottom:"1em",
      display:"flex",
      alignItems:"flex-end",
      border:"2px solid gray",
      borderRadius:".2em",
    },
    divAsInput: {
      display: 'inline-block',
      width: '20em', // Same as the input
      fontSize: '0.8em', // Same as the input
      border: '1px solid #ccc', // Common border styling
      backgroundColor: 'transparent', // White background
      color: '#fff', // Text color
      textOverflow: 'ellipsis', // Truncate text if needed
      borderRadius:"0.2em"
    },
  }
  const compileForm = () => {
    onSubmit({
      ...pin,
      editState: false,
      pinTitle: titleRef.current.value,
      notes: notesState,
      color: chosenColor
    });
    setEditingPinIndex(null)
  };
const getSimpleTimestamp = () => {
  const date = new Date();
  const month = date.getMonth() + 1; // Months are 0-based in JavaScript
  const day = date.getDate();
  const year = date.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
  return `${month}/${day}/${year}`;
};
  const handleAddMessage = (val) => {
    messageRef.current.value = ""
    if (val) {
      let timeStamp = getSimpleTimestamp()
      let newNotes = [...notesState,{note:val,timeStamp:timeStamp}]
      onSubmit({
        ...pin,
        notes: newNotes,
      })
    }
    
  }
  const handleRemoveMessage = (val) => {
    console.log('Val:', val);
    console.log('Notes State:', notesState);
    let newNotes = notesState.filter(note => {
      console.log('Comparing:', note.note, 'with', val);
      return note !== val;
    });
    console.log('New Notes:', newNotes);
    onSubmit({
      ...pin,
      notes:newNotes
    })
  }

const AddEditPool = ({note, index}) =>{
  return(

    <div style={{
      display:"flex",
      flexDirection:"row",
      fontSize:"0.7em",
      alignItems:"center",
      justifyContent:"space-evenly"
    }}
    >
      <div className='button-frame' onClick={(e)=>{
        e.stopPropagation()
        let copy = [...pin.notes]
        let deleted = copy.splice(index,1)
        onSubmit({
          ...pin,
          notes: copy,
        })
      }}>
        <BsTrash3Fill />
      </div>
      <input value={note} className='string-frame' />
      
    </div>
  )
}

switch(chosenToolOption){
  case "editPin":
    return (
      <div style={{
        position: 'fixed',
        top: 0, bottom: 0, left: 0, right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        zIndex:"100"
      }}>
        <form style={{
            backgroundColor: '#292929',
            marginTop:"2.5em",
            width: "95%",
            display: "flex",
            flexDirection: "row",
            flexWrap:"wrap",
            fontSize: "1.5em",
            alignItems: "center",
            borderRadius: "1em",
            filter: `drop-shadow(0px 0px 20px ${chosenColor})`,
            color: "white",
            border:"2px solid white",
            overflow:"auto"
          }}>
            <div style={{flexDirection:"row",...css.optionBox,marginBottom:"0.25em"}}>
              <div style={{fontSize: "1.2em", margin: "0.2em"}}>Edit a Pin.</div>
              <div style={{fontSize: "0.7em", margin: "0.6em"}}>Add notes and keep organized.</div>
            </div>
            <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"28%",height:"8em",}}>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Set the title:</div>
              <div style={{...css.buttonsWrapper,margin:"0 0.2em"}}>
                <input type="text" maxLength={30} defaultValue={pin.pinTitle} ref={titleRef} style={{fontSize: "0.8em",width:"13em"}}></input>
              </div>
              <div style={{height:"1em"}}></div>
              <div style={{fontSize: "1em", margin: "0.2em"}}>Set the color:</div>
              <div style={{...css.buttonsWrapper}}>
                <div  style={{...css.buttonsFrame,width:"2em"}}><input value={chosenColor} ref={colorRef} onChange={()=>{setChosenColor(colorRef.current.value)}} type={"color"} style={{border:"none",cursor:"pointer"}}></input></div>
                <div onClick={()=>{setChosenColor("#ff0000")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ff0000"}}></div></div>
                <div onClick={()=>{setChosenColor("#ffa500")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffa500"}}></div></div>
                <div onClick={()=>{setChosenColor("#ffff00")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffff00"}}></div></div>
                <div onClick={()=>{setChosenColor("#1be600")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#1be600"}}></div></div>
                <div onClick={()=>{setChosenColor("#0000ff")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#0000ff"}}></div></div>  
              </div>
            </div>
            <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"65%",height:"8em",overflow:"auto"}}>
            <div style={{fontSize: "1em", margin: "0.2em"}}>Notes:</div> 
              <div>
                <input type="text" placeholder="Type here to add a new note..." ref={messageRef} style={{ fontSize: "0.8em", width: "21.8em" }} />
                <button type={"button"} onClick={() => handleAddMessage(messageRef.current.value)}>✔ Add to List</button>
              </div>
              {notesState.map((m, index) => (
                <div style={{display:"flex",flexDirection:"row"}} key={index}>
                  <div style={css.divAsInput}>• {m.note}</div> {/* Replaced input with styled div */}
                  <div style={css.timeStampBox}>{m.timeStamp}</div>
                  <button type={"button"} onClick={()=>handleRemoveMessage(m)}>
                    <BsTrash3Fill/>
                  </button>
                </div>
              ))}
            </div>
            <div style={{width:"100%",display:"flex",flexDirection:"row",borderTop:"4px solid white"}}>
  
                <div style={{...css.topButtons,fontWeight:"bold",backgroundColor:"white",color:"black"}} onClick={compileForm}>Save Pin</div>
                <div style={{...css.topButtons,backgroundColor: "transparent"}} onClick={()=>{
                onCancel()
              }}>Cancel</div>
            </div>
          </form>
      </div>
    );
    case "addPin":
      return (
        <div style={{
          position: 'fixed',
          top: 0, bottom: 0, left: 0, right: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          zIndex:"100"
        }}>
          <form style={{
              backgroundColor: '#292929',
              marginTop:"2.5em",
              width: "12em",
              display: "flex",
              flexDirection: "row",
              flexWrap:"wrap",
              fontSize: "1.5em",
              alignItems: "center",
              borderRadius: "1em",
              filter: `drop-shadow(0px 0px 20px ${chosenColor})`,
              color: "white",
              border:"2px solid white",
              overflow:"auto"
            }}>
              <div style={{flexDirection:"row",...css.optionBox}}>
                <div style={{fontSize: "1.2em", margin: "0.2em"}}>Add a Pin.</div>
              </div>
              <div style={{flexDirection:"column",...css.optionBox,alignItems:"flex-start",width:"100%",height:"8em",justifyContent:"space-evenly"}}>
              <div style={{fontSize: "1em", margin: "0.2em"}}>Set the title:</div>
                <div style={{...css.buttonsWrapper,margin:"0 0.2em"}}>
                  <input type="text" maxLength={30} ref={titleRef} style={{fontSize: "0.8em",width:"13em"}}></input>
                </div>
                <div style={{fontSize: "1em", margin: "0.2em"}}>Set the color:</div>
                <div style={{...css.buttonsWrapper}}>
                  <div  style={{...css.buttonsFrame,width:"2em"}}><input value={chosenColor} ref={colorRef} onChange={()=>{setChosenColor(colorRef.current.value)}} type={"color"} style={{border:"none",cursor:"pointer"}}></input></div>
                  <div onClick={()=>{setChosenColor("#ff0000")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ff0000"}}></div></div>
                  <div onClick={()=>{setChosenColor("#ffa500")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffa500"}}></div></div>
                  <div onClick={()=>{setChosenColor("#ffff00")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#ffff00"}}></div></div>
                  <div onClick={()=>{setChosenColor("#1be600")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#1be600"}}></div></div>
                  <div onClick={()=>{setChosenColor("#0000ff")}} style={{...css.buttonsFrame,width:"1.4em"}}><div style={{...css.buttonColor,backgroundColor:"#0000ff"}}></div></div>  
                </div>
              </div>
              <div style={{width:"100%",display:"flex",flexDirection:"row",borderTop:"4px solid white"}}>
    
                  <div className='saveButton' style={{...css.topButtons,fontWeight:"bold",backgroundColor:"white",color:"black"}} onClick={compileForm}>Save Pin</div>
                  <div style={{...css.topButtons,backgroundColor: "transparent"}} onClick={()=>{
                  onCancel()
                }}>Cancel</div>
              </div>
            </form>
        </div>
      );
}
  
};

export default PinPopup