import "./Dash.css"
import React from "react";
import {
  BsPinMapFill,
  BsFileEarmarkBarGraph,
  BsFillPersonVcardFill,
  BsPeopleFill,
  BsRocketTakeoffFill,
  BsGearWideConnected,
  BsHouseFill,
  BsBuildingFill,
  BsDoorOpenFill,
  BsClipboardDataFill,
  BsList
} from 'react-icons/bs';
import VoltLogo from "../assets/art/name_3.svg"
import {FBAuth} from '../Firebase/firebase'
import {signOut} from "firebase/auth";
import { useNavigate } from 'react-router-dom';


const Sidebar = ({setUserData,setUID,userCompanyData,setUserCompanyData,chosenPage,setChosenPage,sidebarStatus,setSidebarStatus,chosenCompany,setChosenCompany}) => {
  const navigate = useNavigate()

  const logout = () => {
    try {
      signOut(FBAuth)
      console.log("Successfully logged out")
    } catch {
      console.error("Error logging out")
    }
    setUserData({})
    setUserCompanyData({})
    setUID("")
    navigate("/")
  }

  const companyButtonClick = (co) => {
    setChosenPage("company")
    setChosenCompany(co)
  }

  const CompanyButton = () => {
    if (userCompanyData.length < 1) {
    } else {
      return (
        <>
          {userCompanyData.map((company,i)=>{
            // console.log(company)
            return(
              <div onClick={()=>companyButtonClick(company)} className="sidebar-item" key={company.companyName}>
                <div className="sidebar-indicator" style={{backgroundColor: (chosenPage=="company"&&chosenCompany==company.companyName)?"var(--indicator)":"transparent" }} />
                <BsBuildingFill style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">{company.companyName}</div>
              </div>
            )
          })}
        </>
      )
    }
  }

  return(
    <div className="sidebar" style={{"marginLeft": sidebarStatus==false ? "-15rem" : "0"}}>
      <div className="sidebar-hamburger" onClick={()=>setSidebarStatus(!sidebarStatus)}>
        <BsList style={{color:"#fff"}}/>
      </div>
        <div className="sidebar-header">
          <img className="sidebar-header-img" src={VoltLogo} alt="logo" />
        </div>
        <div className="sidebar-body">
          <CompanyButton />
          <div onClick={()=>setChosenPage("plan-room")} className="sidebar-item">
            <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="plan-room"?"var(--indicator)":"transparent" }} />
            <BsPinMapFill style={{fontSize: "1.2rem"}}/>
            <div className="sidebar-item-text">PlanRoom</div>
          </div>
          <div onClick={()=>setChosenPage("budget-books")} className="sidebar-item">
            <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="budget-books"?"var(--indicator)":"transparent" }} />
            <BsClipboardDataFill style={{fontSize: "1.2rem"}}/>
            <div className="sidebar-item-text">BuildBooks</div>
          </div>
          <div onClick={()=>setChosenPage("profile")} className="sidebar-item">
            <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="profile"?"var(--indicator)":"transparent" }} />
            <BsFillPersonVcardFill style={{fontSize: "1.2rem"}}/>
            <div className="sidebar-item-text">Profile</div>
          </div>
          <div onClick={()=>setChosenPage("users")} className="sidebar-item">
            <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="users"?"var(--indicator)":"transparent" }} />
            <BsPeopleFill style={{fontSize: "1.2rem"}}/> 
            <div className="sidebar-item-text">People</div>
          </div>
          <div onClick={()=>setChosenPage("settings")} className="sidebar-item">
            <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="settings"?"var(--indicator)":"transparent" }} />
            <BsGearWideConnected style={{fontSize: "1.2rem"}}/> 
            <div className="sidebar-item-text">Settings</div>
          </div>
          {/* <AdminButton /> */}
        </div>
        <div className="sidebar-footer">
        <div onClick={()=>logout()} className="sidebar-item">
          <div className="sidebar-indicator" />
          <BsDoorOpenFill style={{fontSize: "1.2rem"}}/>
          <div className="sidebar-item-text">Log Out</div>
        </div>
        </div>
      </div>
    )
  }

export default Sidebar;