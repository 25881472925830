import "./Dash.css"
import React, {useState, useEffect} from "react";
import DashboardAdmin from "./DashboardAdmin";
import DashboardProfile from "./DashboardProfile";
import DashboardSettings from "./DashboardSettings";
import DashboardUsers from "./DashboardUsers";
import DashboardCompany from './DashboardCompany'
import DashboardHome from './DashboardHome'
import DashboardPlanRoom from './DashboardPlanRoom'
import DashboardBudgetBooks from './DashboardBudgetBooks'
import {useUserData} from "../Contexts/UserDataContext"

import { useNavigate } from 'react-router-dom';
import Sidebar from "./Sidebar";

const DashboardSplash = () => {
  const navigate = useNavigate()
  const {userData,userCompanyData,loading, setUserData,setUserCompanyData,setUID} = useUserData()
  const [chosenPage, setChosenPage] = useState("home")
  const [chosenCompany,setChosenCompany] = useState("")
  const [sidebarStatus,setSidebarStatus] = useState(true)
  const [adminOfCompanies,setAdminOfCompanies] = useState([])
  
  useEffect(() => {
    if (!userData && !loading) {
      navigate("/login");
    } else {
      userCompanyData.map((co,i)=>{
        co.admins.map((aUser,i)=>{
          if (aUser == userData.uid) {
            setAdminOfCompanies([...adminOfCompanies,co.companyName])
          }
        
        })
      })
    }
  }, [userData, navigate]);





  const DashboardSplitter = () => {
    switch(chosenPage){
      case "company":
        return <DashboardCompany coData={chosenCompany} adminOfCompanies={adminOfCompanies} />
      case "home":
        return <DashboardHome />
      case "plan-room":
        return <DashboardPlanRoom />
      case "budget-books":
        return <DashboardBudgetBooks />
      case "profile":
        return <DashboardProfile />
      case "settings":
        return <DashboardSettings />
      case "users":
        return <DashboardUsers />
      case "admin":
        return <DashboardAdmin />
      default:
        return <DashboardProfile />
    }
  }


  
  return(
    <div className="dash-app">
      <Sidebar 
        setUserData={setUserData} 
        setUserCompanyData={setUserCompanyData} 
        setUID={setUID} 
        userCompanyData={userCompanyData} 
        chosenPage={chosenPage} 
        setChosenPage={setChosenPage} 
        sidebarStatus={sidebarStatus} 
        setSidebarStatus={setSidebarStatus} 
        setChosenCompany={setChosenCompany} 
        chosenCompany={chosenCompany}/>
      <div className="dash-content" style={{"marginLeft":sidebarStatus==false ? "0rem" : "15rem", "width":sidebarStatus==false ? "100%" : "calc(100% - 15rem)"}}>
        <DashboardSplitter />
      </div>
    </div>
  )
}

export default DashboardSplash;