import React, { useState, useEffect } from 'react';


const ShadowPin = ({viewBoxRef}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const svg = viewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      setPosition({ x: svgP.x, y: svgP.y });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <g
      transform={`translate(${position.x}, ${position.y - 60})`}
    >
      <g className="">
        <g>
          <line x1={-3} y1={-60} x2={0} y2={60} stroke="gray" strokeWidth="12"/>
          <line x1={+3} y1={-60} x2={0} y2={60} stroke="black" strokeWidth="12"/>
          <circle cx={0} cy={-130} r="77" fill="red"/>
          <circle cx={0} cy={0} r="30" fill="rgba(0,0,0,0.1)" />
        </g>
      </g>
    </g>
  );
};

export default ShadowPin;