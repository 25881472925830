import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBm_iaa_8MgFXUiY8SURpL3i9DWPDHBkV0",
  authDomain: "plan-room.firebaseapp.com",
  projectId: "plan-room",
  storageBucket: "plan-room.appspot.com",
  messagingSenderId: "996639395549",
  appId: "1:996639395549:web:2a98fa0003f0cbf89bd534",
  measurementId: "G-VK80RYEEXS"
};
export const FBApp = initializeApp(firebaseConfig);
export const FBAuth = getAuth(FBApp)
export const FBStorage = getStorage(FBApp)
export const FBDataBase = getFirestore(FBApp)