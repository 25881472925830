import {useState,useEffect} from 'react'

const MoveMessage = ({viewBoxRef,framePos}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const inverseScale = 1/ (framePos.scale * 2.5)

  useEffect(()=>{
    const handleMouseMove = (e) => {
      const svg = viewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      setPosition({ x: svgP.x, y: svgP.y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  },[])

  return (
    <svg>
      <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
      <rect
        x={35} 
        y={50} 
        width={390} 
        height={100} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={40}
        y={90}
        fill="white"
        fontSize="2.2em"
      >
        Click & hold pin to drag
      </text>
      <text
        x={40}
        y={130}
        fill="white"
        fontSize="2.2em"
      >
         Press ESC to cancel
      </text>
      </g>
    </svg>
  );
}

export default MoveMessage