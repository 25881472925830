import { useReducer, useState,useRef, useEffect } from "react"
import {BsArrowRightSquare,BsArrowLeftSquare,BsPrinter,
  BsSlash,BsFillSunsetFill,BsSunrise,
  BsClipboardData, BsPinMap, BsCashStack,
  BsPen,BsBookmarks,BsPersonCircle, BsHouse,
  BsArrowLeftCircle,BsBox2,BsBriefcase,BsEnvelopePaper} from 'react-icons/bs'
  import {SlSocialDropbox} from 'react-icons/sl'
  import {RxRulerSquare} from 'react-icons/rx'
  import {TfiSpray} from 'react-icons/tfi'
  import { useNavigate } from 'react-router-dom';
const HeaderBar = ({pageNumRef,
  darkMode,
  setDarkMode,
  pageCount,
  setPageNum,
  setChosenTool,
  setChosenOptionsTool,
  chosenTool,
  setListPageToggle,
  setChosenProduct,
  isDemo,
}) =>{
  const navigate = useNavigate();
useEffect(()=>{
  setChosenProduct(null)
},[chosenTool])

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const DarkModeToggle = () => {
  if (darkMode == false || darkMode == undefined) {
  return(
    <div className="tool-icon-box"
    onClick={()=>{setDarkMode(true)
      localStorage.setItem('darkMode', true)
    }}
    >
      <div className="tool-icon"><BsSunrise/></div>
      <div className="tool-text">LIGHT</div>
    </div>
  )
  } else {
    return(
      <div className="tool-icon-box"
      onClick={()=>{setDarkMode(false)
        localStorage.setItem('darkMode', false)}}
        >
          <div className="tool-icon"><BsFillSunsetFill/></div>
          <div className="tool-text">DARK</div>
        </div>
    )
  }
}
  
const DemoBar = () => {
  if (isDemo) {
    return (
      <div onClick={()=>(navigate("/"))} id="headerBarDemo">
        <div>&nbsp;← &nbsp;EXIT DEMO</div>
      </div>
    )
  }
}

return(
  
  <div style={{
    position: "fixed",
    top: "0",
    zIndex: "10"
  }}>
      <DemoBar />
       <div id="headerBar">
       {/* <div className="spacer" style={{width: "60%"}}></div> */}
       <div className="tool-icon-box" onClick={()=>{
        // setChosenLayer(chosenLayer == "markup" ? "estimate" : "markup")
         setChosenTool("productsTool")
         setChosenOptionsTool("")}}>
           <div className="tool-icon"><BsCashStack/></div>
           <div className="tool-text">ESTIMATE</div>
         </div>
       <div className="tool-icon-box" onClick={()=>{setChosenTool("measureTool")
         setChosenOptionsTool("")}}>
           <div className="tool-icon"><RxRulerSquare/></div>
           <div className="tool-text">MEASURE</div>
         </div>
       <div className="tool-icon-box" onClick={()=>{setChosenTool("markupTool")
         setChosenOptionsTool("")}}>
           <div className="tool-icon"><TfiSpray/></div>
           <div className="tool-text">MARKUP</div>
         </div>
       <div className="tool-icon-box" onClick={()=>{setChosenTool("pinsTool")
         setChosenOptionsTool("")}}>
           <div className="tool-icon"><BsPinMap/></div>
           <div className="tool-text">PINS</div>
         </div>
         <div style={{width: "5%"}}></div>
 
         <div className="tool-icon-box"
         onClick={()=>{if(pageNumRef.current.value == 1){return}else {pageNumRef.current.value-- && setPageNum(pageNumRef.current.value)}}}>
           <div className="tool-icon"><BsArrowLeftSquare/></div>
           <div className="tool-text">BACK</div>
         </div>
       <div style={{marginRight: "-4%"}}></div>
       <div className="pageNumInput-container">
         <input
           defaultValue={1}
           max={parseInt(pageCount)}
           min={1}
           onChange={()=>setPageNum(pageNumRef.current.value)}
           ref={pageNumRef} type="number" 
           style={{width: "100%", fontSize: "1em", height: "0.8em",backgroundColor:"transparent", color: "white"}}
         ></input> <div> <BsSlash/> </div> <div>{pageCount}</div>
       </div>
       <div style={{marginLeft: "-4%"}}></div>
       <div className="tool-icon-box"
       onClick={()=>{if(pageNumRef.current.value == pageCount){return}else {pageNumRef.current.value++ && setPageNum(pageNumRef.current.value)}}}>
           <div className="tool-icon"><BsArrowRightSquare/></div>
           <div className="tool-text">NEXT</div>
         </div>
         <div style={{width: "5%"}}></div>
         <div className="tool-icon-box" onClick={()=>{
           setChosenTool(chosenTool == "pagesTool" ? " " : "pageTool")
           setChosenOptionsTool("")}}>
           <div className="tool-icon"><BsBookmarks/></div>
           <div className="tool-text">PAGES</div>
         </div>
         <div className="tool-icon-box" onClick={()=>{setChosenTool("peopleTool")
           setChosenOptionsTool("")}}>
           <div className="tool-icon"><BsPersonCircle/></div>
           <div className="tool-text">PEOPLE</div>
         </div>
         <DarkModeToggle/>
         <div className="tool-icon-box" onClick={()=>{
          if (isDemo) {
            navigate("/")
          } else {
            navigate('/plan-room/')
            setChosenOptionsTool("")
            setChosenTool("")
            pageNumRef.current.value = 1
            setPageNum(1)
          }
          }}>
           <div className="tool-icon"><BsHouse/></div>
           <div className="tool-text">HOME</div>
         </div>
     </div>
    
  </div>
)

}

export default HeaderBar