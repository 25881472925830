import { useEffect, useState,useRef } from "react";

const DeleteMessage = ({viewBoxRef,chosenToolOption,framePos}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [type,setType] = useState("")
  const inverseScale = 1/ (framePos.scale * 2.5)
  useEffect(() => {

    if (chosenToolOption == "trashMarkup") {setType("mark")
    } else if (chosenToolOption == "trashPin") {setType("pin")
    } else if (chosenToolOption == "trashMeasure") {setType("measure")}

    const handleMouseMove = (e) => {
      const svg = viewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      setPosition({ x: svgP.x, y: svgP.y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <svg>
      <defs>
        <filter id="redDropShadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor="red"/>
        </filter>
      </defs>
      <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
      <rect
        x={35} 
        y={50} 
        width={420} 
        height={100} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
        filter="url(#redDropShadow)" // This applies the filter
        
      />
      <text
        x={40}
        y={90}
        fill="white"
        fontSize="2.2em"
      >
        Choose {type} to delete
      </text>
      <text
        x={40}
        y={130}
        fill="white"
        fontSize="2.2em"
      >
         Press ESC to cancel
      </text>
      </g>
    </svg>
  );
};

export default DeleteMessage