import frontLogo from '../assets/art/6.6.1v_front.png'
import backLogo from '../assets/art/gear.png'
import './SplashStyles.css'
import nameLogo from '../assets/art/name_3.svg'
import NavBar from './NavBar';
import Footer from './Footer';
import {
  BsLightningChargeFill,
  BsFillFileEarmarkTextFill,
  BsFillPinMapFill,
  BsBoxSeamFill,
} from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import tools1 from '../assets/svg/tools1.svg'
import caterpillar from '../assets/svg/caterpillar.svg'
import hatSticker from '../assets/hat.jpg'
import jobsite from '../assets/jobsite.jpg'
import larry from '../assets/larry.jpg'

const Splash = () => {
  const navigate = useNavigate();

  return (
    <div className="App">
      <NavBar />
      <div className="App-header">
        <div className='hero-wrapper'>
          
        <img src={nameLogo} style={{height:"26em"}} alt="logo"/>
          <div className='hero-text-box'>
            <p style={{fontSize:"2em", fontFamily:"Impact", color:"#00aeef"}}>
              We create construction apps for builders who know their time is money.
            </p>
            <p style={{fontSize:"1.2em"}}>Volt is a catalog of construction applications. Every tool we make is built to allow instant collaboration between you and your entire team, with a simple user interface. Quit wasting your precious time on old software, and try out Volt's simple and modern Toolkit.</p>
            <div className='button-box'>
              <div onClick={()=>{navigate('/catalog')}} className='hero-button' style={{backgroundColor:"#e6e7e8", color:"black"}}>View Catalog</div>
              <div onClick={()=>{navigate('/plan-room/app', { state: { myData: "Riverbend", isDemo: true } })}} className='hero-button' style={{backgroundColor:"#00aeef"}}><BsLightningChargeFill style={{color:"#ffde17"}} className='NavButtonIcon' />Try Demo</div>
            </div>
          </div>
        </div>

        <div className='pane-wrapper' style={{height:"280px", backgroundColor:"#f7f7f7", display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
        <img src={tools1} style={{height:"14em"}} alt="logo"/>
          <div style={{textAlign:"center"}}>
            <h2>Volt is under Construction.</h2>
            <div style={{textAlign:"left"}}>
              <p>Please excuse the mess while we are developing the finest products.</p>
              <p>Our team is working hard to bring you the best software experience in the construction industry.</p>
              <p>We believe in measuring twice and cutting once.</p>
            </div>

          </div>
          <img src={caterpillar} style={{height:"14em"}} alt="logo"/>
        </div>

        <div className='pane-wrapper' style={{height:"460px", backgroundColor:"#1c1c1c", color:"#fff"}} >
          <h1>Which solution fits your needs?</h1>
          <div className='pane-box-wrapper'>
            <div className='pane-box' style={{}}>
              <h2>Takeoffs and Bidding</h2>
              <BsFillPinMapFill style={{fontSize:"1.5em",color:"#ffde17"}} />
              <div className='pane-details'>
              <p>- Upload plans to our secure servers for team collaboration. Use pins, bookmarks, and drawings to work together virtually.</p>
              <p>- Simplify estimation with easy item pricing. Assign names and prices to various takeoff types, catering to all your pricing needs.</p>
              </div>
              <div style={{height:"20px"}} />
            <div className='pane-button'>
              <div className='pane-button-text'>Read More...</div>
            </div>
            </div>
            <div className='pane-spacer' style={{}}></div>
            <div className='pane-box' style={{}}>
              <h2>Invoice Generation</h2>
              <BsFillFileEarmarkTextFill style={{fontSize:"1.5em",color:"#ffde17"}} />
              <div className='pane-details'>
              <p>- Effortlessly generate and manage your crucial invoices. Designed to relieve the burden of document creation.</p>
              <p>- This app is free with no limits, to all users, forever. Our motto is to help build small business, so take this one is on us.</p>
              </div>
              <div style={{height:"20px"}} />
              <div className='pane-button'>
                <div className='pane-button-text'>Read More...</div>
              </div>
            </div>
            <div className='pane-spacer' style={{}}></div>
            <div className='pane-box' style={{}}>
              <h2>3D Kitchen Design</h2>
              <BsBoxSeamFill style={{fontSize:"1.5em",color:"#ffde17"}} />
              <div className='pane-details'>
              <p>- Create top-down and elevation kitchen drawings in 3D. Collaborate in virtual space with limitless drag & pan functionality.</p>
              <p>- All you need to do is place the cabinets; Our homemade "Kit-AI" adds dimensions, applies countertops, installs toekicks, and more.</p>
              </div>
              <div style={{height:"20px"}} />
              <div className='pane-button'>
                <div className='pane-button-text'>Read More...</div>
              </div>
            </div>
          </div>
        </div>

    <div className='about-pane-wrapper' style={{backgroundColor:"#ffffff", color:"#000"}} >
      <div className="about-img-wrapper">
        <img className="about-img" src={hatSticker} />
      </div>
      <div className="about-pane-description">
        <h1>About Our Company</h1>
        <div className="catalog-spacer"></div>
        <p className="catalog-text">The Founders of Volt Applications are all past or current construction workers. We know this industry. We are working hard to build our suite of software, because we know the industry needs new and innovative solutions to million dollar problems.</p>
      </div>
    </div>

    <div className='about-pane-wrapper' style={{backgroundColor:"#f7f7f7", color:"#000"}} >
      <div className="about-pane-description">
        <h1>Our Mission</h1>
        <div className="catalog-spacer"></div>
        <p className="catalog-text">Our mission is to revolutionize the construction industry by providing user-friendly, efficient, and cutting-edge software solutions. Our products are all Real-Time allowing everyone to work together at the same time. Also our products are simple and user friendly which allows non-tech workers to still get the job done.</p>
      </div>
      <div className="about-img-wrapper">
        <img className="about-img" src={larry} />
      </div>
    </div>

    <div className='about-pane-wrapper' style={{backgroundColor:"#ffffff", color:"#000"}} >
      <div className="about-img-wrapper">
        <img className="about-img" src={jobsite} />
      </div>
      <div className="about-pane-description">
        <h1>Our Vision</h1>
        <div className="catalog-spacer"></div>
        <p className="catalog-text">We envision a future where jobsites run more effectively, where less product is wasted, and where every contractor has the same information. Tough job, we know, but we will work tirelessly until our vision is achieved.</p>
      </div>
    </div>

        





        <Footer />
      </div>
    </div>
  );
}

export default Splash;
